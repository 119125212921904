import { COUNTRIES_INFOS, PhoneNumber } from "@shift/gears-design-system";
import { IReportState, IVehicleInfo } from "state";

const getPhoneNumber = (number: string | undefined, dialCode: string | undefined) => {
  if (dialCode && !dialCode.startsWith("+")) {
    dialCode = `+${dialCode.trim()}`;
  }
  const countryCode = COUNTRIES_INFOS.find((x) => x.dialCode === dialCode)?.code;
  const phoneNumber: PhoneNumber = {
    countryCode,
    dialCode: dialCode?.replace("+", ""),
    number,
  };
  return phoneNumber;
};

export const mapMyInfoToReportState = (myInfo: any) => {
  const state: IReportState = {
    personalInfo: {
      ufin: myInfo.uinfin?.value,
      name: myInfo.name?.value,
      sex: myInfo.sex?.desc,
      dob: myInfo.dob?.value,
    },
    contactInfo: {
      email: myInfo.email?.value,
      phone: getPhoneNumber(myInfo.mobileno?.nbr.value, myInfo.mobileno?.areacode.value),
    },
    regAdd: {
      countryCode: myInfo.regadd?.country.code,
      country: myInfo.regadd?.country.desc,
      unit: myInfo.regadd?.unit.value,
      street: myInfo.regadd?.street.value,
      block: myInfo.regadd?.block.value,
      postal: myInfo.regadd?.postal.value,
      floor: myInfo.regadd?.floor.value,
      building: myInfo.regadd?.building.value,
    },
    ownedVehicles: myInfo.vehicles.map((item: any) => {
      const _vehicleInfo: IVehicleInfo = {
        vehicleno: item.vehicleno?.value,
        type: item.type?.value,
        make: item.make?.value,
        model: item.model?.value,
        propellant: item.propellant?.value,
        enginecapacity: item.enginecapacity?.value,
        chassisno: item.chassisno?.value,
        firstregistrationdate: item.firstregistrationdate?.value,
        effectiveownershipdate: item.effectiveownership?.value,
      };
      return _vehicleInfo;
    }),
    licenceInfo: {
      classes: myInfo.drivinglicence?.qdl?.classes.map((item: any) => {
        return { class: item.class?.value, issueDate: item.issuedate?.value };
      }),
      validity: myInfo.drivinglicence?.qdl?.validity?.code,
      validityDesc: myInfo.drivinglicence?.qdl?.validity?.desc,
    },
  };
  return state;
};
