import React, { useContext, useState } from "react";
import { Button, Grid, GridItem } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { PageTitle } from "components/PageTitle";
import { PageText } from "components/PageText";
import { IContactInfo, useAppState } from "state";
import styled from "styled-components";
import { SecondaryTextButton } from "components";
import { useHistory } from "react-router-dom";
import { routes } from "entrypoint/Routing";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { GearsVehicleAccidentReportHistoryTitle } from "../../constants";
import { PageHeaderAccidentHistoryReport } from "components/PageHeaderAccidentHistoryReport";

export const ContactInformationAccidentHistoryReport = () => {

    useDocumentTitle(GearsVehicleAccidentReportHistoryTitle);
    const history = useHistory();
    const {
        reportState: { contactInfo },
    } = useAppState();

    const hasEmail = (contactInfo: IContactInfo | undefined) => contactInfo?.email !== undefined && contactInfo?.email !== ""

    return (
        <>
            <PageHeaderAccidentHistoryReport />
            <ContentWrapper>
                <Grid direction="column" style={{ minHeight: "100%" }}>
                    <GridItem flex={1}>
                        <PageTitle>Contact information</PageTitle>
                        {hasEmail(contactInfo) ?
                            <PageText>
                                Please confirm that the email below is the one that you want the report to be sent to.

                                <EmailContainer>{contactInfo?.email} </EmailContainer>

                                if you want to use another, type in the email you want to use to get your report.

                            </PageText>
                            :
                            <PageText>

                                <EmailContainer>{hasEmail(contactInfo) ? contactInfo?.email : "No email provided for this user."} </EmailContainer>

                                Please select the email that you want the report to be sent to.
                            </PageText>}

                    </GridItem>
                    <GridItem flex={100} style={{ display: "inline-grid" }}>
                        {<SecondaryTextButton onClick={() => history.push(routes.accidentHistoryReportChangeContactInfo)}>{hasEmail(contactInfo) ? "Select another email" : "Select email"}</SecondaryTextButton>}
                    </GridItem>
                    <GridItem flex={1} style={{ display: "inline-grid" }}>
                        {<Button disabled={!hasEmail(contactInfo)} onClick={() => history.push(routes.accidentHistoryReportVehicleSelection)}>Continue</Button>}
                        <br />
                    </GridItem>
                </Grid>
            </ContentWrapper >
        </>
    );
};
const EmailContainer = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;

`;