import React, { useEffect } from "react";
import { PageHeader, PageText, SecondaryTextButton } from "components";
import { useAppState } from "state";
import { useHistory } from "react-router-dom";
import { routes } from "entrypoint/Routing";
import styled from "styled-components";
import { Step, StepDescription } from "components/Steps";
import { Grid, GridItem } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { useErrorMessageContext } from "components/ErrorMessageProvider";
import { dateUtils } from "utils/dates";

export const Confirmation = () => {
  const history = useHistory();
  const { displayMessage } = useErrorMessageContext();

  const {
    reportState: { submitInfo },
  } = useAppState();

  if (!submitInfo) {
    history.push(routes.newReport);
  }

  const { date: submissionDate, reportId } = submitInfo!;

  useEffect(() => {
    if (submitInfo?.errors)
      displayMessage(submitInfo.errors);
  }, [displayMessage, submitInfo])

  return (
    <>
      <PageHeader hideBackButton/>
      <ContentWrapper>
        <Grid direction="column" style={{ minHeight: "100%" }}>
          <GridItem flex={100} style={{ display: "table" }}>
            <Step stepId={1} done>
              Notify your accident on this site
            </Step>
            <br />
            <PageText>
              You have successfully notified your accident on {dateUtils.toSingaporeDateFormat(submissionDate)} at{" "}
              {dateUtils.toSingaporeTimeFormat(submissionDate)}
              , we have sent you an <b>email confirmation.</b>
            </PageText>
            <Grid justifyPlacement="center">
              <GridItem>
                <PageText>Your accident report number is</PageText>
              </GridItem>
            </Grid>
            <Grid justifyPlacement="center">
              <GridItem>
                <ReportIdContainer>{reportId}</ReportIdContainer>
              </GridItem>
            </Grid>
            <br />
            <PageText>
              <b>To finish reporting your accident you have to:</b>
            </PageText>
            <Step stepId={2}>
              Report the accident at an ARC
            </Step>
            <StepDescription>
              Bring your accident vehicle and <b>accident report number</b> to an Authorised Reporting Centre (ARC) before the end of the next
              working day.
            </StepDescription>
            <br />
            <PageText>
              Submission of this GEARS Online Accident Notification does not constitute reporting under the GIA Motor Claims
              Framework. To complete your report, you must bring the accident vehicle to an ARC within 1 working day from the date
              of the accident.
            </PageText>
            <PageText>
              Failure to do so within the stipulated timeframe may impact your claim and/or policy renewal terms.
            </PageText>
          </GridItem>
          <GridItem flex={1} style={{ display: "inline-grid" }}>
            <SecondaryTextButton onClick={() => history.push(routes.newReport)}>Go to home page</SecondaryTextButton>
            <br />
          </GridItem>
        </Grid>
      </ContentWrapper>
    </>
  );
};

// const getGoogleSearchArcLink = (arc: IArcModel | undefined) => {
//   if (!arc) return "Authorised Reporting Center";

//   const { arcName, address } = arc;

//   const clearText = (value: string) => value.replaceAll(" ", "+").replaceAll(",", "");

//   const url = encodeURI(`https://www.google.com/maps/search/?api=1&query=${clearText(arcName)}+${clearText(address)}`);

//   return (
//     <a href={url} target="_blank" rel="noreferrer">
//       {arcName}
//     </a>
//   );
// };

const ReportIdContainer = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-top: -5px;
  padding-top: 0px;
`;
