import React, { useContext, useState } from "react";
import { CircularLoader, Grid, GridItem, SubmitButton, ThemeContext, withCustomVariant } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { useAppState } from "state/AppStateProvider";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { SecondaryTextButton } from "components";
import { routes } from "entrypoint/Routing";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { GearsVehicleAccidentReportHistoryTitle } from "../../constants";
import { Check, CloseSvg, DownloadSvg } from "@shift/gears-icons";
import { purchaseStatuses } from '../../constants';
import { DownloadType, useFetch, useGetQuery, useGetQueryWithRetry } from "utils/fetch/FetchProvider";
import { PDFLinkFormatter } from "utils/formatters/PDFLinkFormatter";
import config from "configuration/config";
import { PageHeaderAccidentHistoryReport } from "components/PageHeaderAccidentHistoryReport";
import { useAuth } from "hooks/useAuth";


const PageContent = styled.div`
    text-align:center;
    margin:15px;
`;
const SuccessTitle = styled.p`
    font-weight: bold;
    font-size: 20px;
    color:#1F9E59;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
`;
const FailTitle = styled.p`
    font-weight: bold;
    font-size: 20px;
    color:red;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
`;
const EmailContainer = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 15px;
`;
const FullWidthIconButton = withCustomVariant(SubmitButton, 'button', 'fullWidth');
const StyledDownloadIcon = styled(DownloadSvg)`
font-size: 0.2em;
color:white;
text-decoration: underline;
`;

const StyledCloseIcon = styled(CloseSvg)`
font-size: 1.2em;
color: red;
`;

const StyledCheckIcon = styled(Check)`
font-size: 1.2em;
color:#1F9E59;
`;


const downloader = (dl: DownloadType, id: string) => {
    const { url } = PDFLinkFormatter.transform([id]);
    return dl(url);
};


export const PaymentConfirmationAccidentHistoryReport = (id: any) => {

    useDocumentTitle(GearsVehicleAccidentReportHistoryTitle);
    const history = useHistory();
    const {onUnauthorized} = useAuth();
    const finalPurchaseStatuses = [purchaseStatuses.failed, purchaseStatuses.sent, purchaseStatuses.emailSendingFailed, purchaseStatuses.reportGenerationFailed]
    const {
        reportState: { contactInfo, purchaseId, purchaseStatus },
        updateReportState,
    } = useAppState();
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const { download: dl } = useFetch(onUnauthorized);
    const { colors } = useContext(ThemeContext);

    const condition = (response: any) => {
        return finalPurchaseStatuses.includes(response.status)
    }
    const onFail = (response: any) => {
        updateReportState({ purchaseStatus: purchaseStatuses.paymentFailed });
    };

    const onSuccess = (response: any) => {
        updateReportState({ purchaseStatus: response.status, selectedVehicle: undefined })
    };

    const {loading} = useGetQueryWithRetry(`${config.accidentHistoryReportResourceServer.api.vehicleAccidentReportHistoryStatus}/${purchaseId}` , 30, 2000, condition, onFail, onSuccess, onUnauthorized);

    return (
        <>
            <PageHeaderAccidentHistoryReport />
            <ContentWrapper>
                <Grid direction="column" style={{ minHeight: "100%" }}>
                    {loading ?
                        <>
                            <GridItem flex={1} style={{ display: "inline-grid", justifyContent: "center" }}>
                                <PageContent>Please wait while the payment is completed.</PageContent>
                            </GridItem>
                            <GridItem flex={100} style={{ display: "inline-grid", justifyContent: "center" }}>
                                <CircularLoader color={colors.brand.primary.base} width="10px" size="50px" />
                            </GridItem>
                        </>
                        :
                        <>
                            {purchaseStatus === purchaseStatuses.sent ?
                                <>
                                    <GridItem flex={1}>
                                        <SuccessTitle><StyledCheckIcon withDefault={false} /> Purchase complete.</SuccessTitle>

                                        <PageContent>

                                            The generated report has been sent to this email address:

                                            <EmailContainer>{contactInfo?.email} </EmailContainer>

                                            <b>You can download this report by clicking on the button below.</b>

                                        </PageContent>
                                    </GridItem>
                                    <GridItem flex={100} style={{ display: "inline-grid" }}>
                                        {<FullWidthIconButton icon={!isDownloadingPdf && <StyledDownloadIcon />} disabled={isDownloadingPdf} onClick={() => {
                                            setIsDownloadingPdf(true);
                                            return downloader(dl, purchaseId ? purchaseId.toString() : "").catch(()=>{}).finally(() => setIsDownloadingPdf(false));
                                        }}>{
                                                isDownloadingPdf ? (
                                                    <GridItem>
                                                        <CircularLoader color={colors.brand.primary.base} width="0.2rem" size="1rem" />
                                                    </GridItem>
                                                ) : <GridItem> Download report</GridItem>
                                            }</FullWidthIconButton>}
                                    </GridItem>
                                </> :
                                <GridItem flex={100}>

                                    <FailTitle><StyledCloseIcon withDefault={false} /> Purchase failed.</FailTitle>

                                    <PageContent>
                                        Please try again later or contact customer support:

                                        <EmailContainer>gears-support@shift-technology.com</EmailContainer>
                                        <b> Purchase Id for your request is: #{purchaseId}</b>

                                    </PageContent>
                                </GridItem>
                            }

                        </>
                    }
                    <GridItem flex={1} style={{ display: "inline-grid" }}>
                        {<SecondaryTextButton onClick={() => { history.replace(routes.accidentHistoryReport) }}>Go to home page</SecondaryTextButton>}
                        <br />
                    </GridItem>
                </Grid>

            </ContentWrapper>
        </>
    );
};