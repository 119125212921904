import React from "react";
import { InputForm, InputFormProps } from "@shift/gears-design-system";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 0px;
  margin-bottom: 20px;
`;

export const PaddedInputForm = (props: InputFormProps) => {
  const { children } = props;

  return (
    <Container>
      <InputForm {...props}>{children}</InputForm>
    </Container>
  );
};
