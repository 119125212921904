import { IReportState } from "state";
import { getAccidentHistoryReportMapping, getMapping } from "./getMapping";

export const mapReportStateToGears = (reportState: IReportState) => {
  const obj: any = {};

  getMapping(reportState).forEach((element) => {
    if (element.onlyIf !== undefined && element.onlyIf === false) return;

    if (element.value != null && element.value !== "") {
      const mappedValue = element.mappingFn ? element.mappingFn(element.value) : element.value;
      if (mappedValue != null && mappedValue !== "")
        putValue(mappedValue, obj, element.gearsPath);
    }
  });

  return obj;
};


export const mapAccidentHistoryReportStateToGears = (reportState: IReportState) => {
  const obj: any = {};

  getAccidentHistoryReportMapping(reportState).forEach((element) => {
    if (element.onlyIf !== undefined && element.onlyIf === false) return;

    if (element.value != null && element.value !== "") {
      const mappedValue = element.mappingFn ? element.mappingFn(element.value) : element.value;
      if (mappedValue != null && mappedValue !== "")
        putValue(mappedValue, obj, element.gearsPath);
    }
  });

  return obj;
};


function putValue(value: any, obj: any, path: string) {
  const levels = path.split(".");
  var current = obj;

  levels.forEach((level, index, array) => {
    const cleanLevel = level.replace("[", "").replace("]", "");
    if (index > array.length - 2) current[cleanLevel] = value;
    else if (current[cleanLevel] === undefined) current[cleanLevel] = level.startsWith("[") ? [] : {};
    current = current[cleanLevel];
  });
}
