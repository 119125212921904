import { FileInfo } from "@shift/gears-design-system";
import { IAddressInfo, ILicenceInfo } from "state";
import { dateUtils } from "utils/dates";
import { AttachmentType, getAttachmentType } from "utils/documents/typesAndCategories";
import { vehicleDrivingLicenseValidityStatuses } from "./constants/drivingLicenseValidityStatuses";

export const mapIdentifierType = (id: string) => (id.startsWith("F") || id.startsWith("G") ? "Passport" : "NationalIdentifier");

export const mapFullAddress = ({ block, street, floor, unit, building, country, postal }: IAddressInfo) =>
  `BLK ${block} ${street} ${floor}-${unit} ${building} ${country} ${postal}`;

export const mapDate = (dateOfBirth: string | undefined) => {
  if (!dateOfBirth) return "";

  const [year, month, date] = dateOfBirth.split("-");

  return `${date ?? "01"}/${month ?? "01"}/${year ?? "1980"}T00:00:00+00:00`; //Some test accounts lack this data. Smart handeling of mapping should happen later on.
};

export const mapGender = (gender: any) => {
  if (gender == null) return "";

  const genderStr = gender.toLowerCase();
  const formattedGender = genderStr.charAt(0).toUpperCase() + genderStr.slice(1);

  return `EntityGender.${formattedGender}`;
};

export const mapDrivingLicenceClass = (licenseClass: string) => {
  if (licenseClass == null) return "";

  var mappedClass;
  switch (licenseClass.toLocaleUpperCase()) {
    case '2':
    case '2A':
    case '2B':
      mappedClass = 'Motorcycle';
      break;
    case '3CA':
    case '3A':
    case '3C':
    case '3':
      mappedClass = 'Car';
      break;
    case '4A':
    case '4':
      mappedClass = 'Bus';
      break;
    case '5':
      mappedClass = 'Truck';
      break;
  }
  return `DrivingLicenseType.${mappedClass}`;
}

export const mapDrivingLicenses = (license: ILicenceInfo) =>
  license.classes?.map((item, i) => ({
    number: `-${i+1}`,
    licenseType: mapDrivingLicenceClass(item.class),
    startDate: dateUtils.toShiftDateTimeOffsetFormat(item.issueDate),
    licenseTypeSourceName: item.class,
    drivingLicenseHasBeenRevoked: mapIsLicenceRevoked(license.validity),
    licenseValidity: mapLicenseValidity(license)?.licenseValidity,
    licenseValiditySourceName: mapLicenseValidity(license)?.licenseValiditySourceName
  }));

export const mapIsLicenceRevoked = (value: string | undefined) => (value == null || value === "V" ? false : true);

export const mapLicenseValidity = (license: ILicenceInfo) => {
  if (!license.validity) return;

  const licenseValidity = vehicleDrivingLicenseValidityStatuses[license.validity.toLocaleUpperCase() ?? ""];
  let resultValidity;
  let resultSourceValidity;
  if (licenseValidity) {
    resultValidity = `DrivingLicenseValidityStatus.${licenseValidity}`;
  } else {
    resultSourceValidity = license.validity
  };
  var mappedValidity = {
    drivingLicenseNumber: `-1`,
    licenseValidity: resultValidity,
    licenseValiditySourceName: resultSourceValidity,
  };
  return mappedValidity;

}

export const mapAttachments = (attachmentType: AttachmentType) => (files: FileInfo[] | undefined) => {
  if (files?.length === 0)
    return undefined;

  return files?.filter(f => getAttachmentType(f.name) === attachmentType).map((p) => ({ id: p.id, documentName: p.name }));
}

