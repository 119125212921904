import React, { useState } from "react";
import { Button, Grid, GridItem, TextInput } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { PageTitle } from "components/PageTitle";
import { PageText } from "components/PageText";
import { useAppState } from "state/AppStateProvider";
import { PaddedInputForm } from "components";
import { IValidationError, getError, validateEmail } from "utils/validators";
import { useHistory } from "react-router-dom";
import { PageHeaderAccidentHistoryReport } from "components/PageHeaderAccidentHistoryReport";

export const ContactInformationChangeAccidentHistoryReport = () => {
    const {
        reportState: { contactInfo },
        updateReportState,
    } = useAppState();

    const history = useHistory();
    const [state, setState] = useState(contactInfo ?? {});
    const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);

    const onSubmit = () => {
        if (isFormValid()) {
            updateReportState({ contactInfo: state });
            history.goBack()
        }
    };

    const isFormValid = () => {
        const validationErrors: IValidationError[] = [];

        validationErrors.push({ field: "email", error: validateEmail(state.email) ? undefined : "Email is not valid" });
        if (validationErrors.find((x) => !!x.error)) {
            setValidationErrors(validationErrors);
            return false;
        }

        return true;
    }

    return (
        <>
            <PageHeaderAccidentHistoryReport />
            <ContentWrapper>
                <Grid direction="column" style={{ minHeight: "100%" }}>
                    <GridItem flex={1}>
                        <PageTitle>Contact information</PageTitle>
                        <PageText>
                            Please type in the email that the report will be sent to.

                        </PageText>
                    </GridItem>
                    <GridItem flex={100} style={{ display: "inline-grid" }}>
                        <PaddedInputForm error={getError("email", validationErrors)}>
                            <TextInput name="email" placeholder="johndoe@mail.com"  onChange={(e) => setState((prev) => ({ ...prev, email: e }))}></TextInput>
                        </PaddedInputForm>
                    </GridItem>
                    <GridItem flex={1} style={{ display: "inline-grid" }}>
                        {<Button disabled={state?.email ? false : true} onClick={() => onSubmit()}>Confirm</Button>}
                        <br />
                    </GridItem>
                </Grid>
            </ContentWrapper>
        </>
    );
};