export interface IValidationError {
  field: string;
  error: string | undefined;
}

export const getError = (fieldName: string, errors: IValidationError[]) => errors.find((x) => x.field === fieldName)?.error;

export const validateEmail = (email: string | undefined | null) => {
  if (email == null) return false;

  const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!email || regex.test(email) === false) {
    return false;
  }
  return true;
};

export const validateVehicleNumber = (vehicleNumber: string | undefined | null) => {
  if (vehicleNumber == null) return false;

  const regex = /^[a-zA-Z0-9]*$/;

  const isVrnValid = vehicleNumber && regex.test(vehicleNumber)
  return isVrnValid;

}
