import React from "react";
import { Route, Switch } from "react-router-dom";
import { SecureRoute, LoginCallback } from "@okta/okta-react";
import config from 'configuration/config';
import { NewReport } from "pages/reporting/NewReport";
import { Home } from "pages/reporting/Home";
import {
  ContactInformation,
  PhotoUpload,
  VehicleInformation,
  PersonalInformation,
  InsurerInformation,
  ReviewReportDetails,
  ReviewMyInfoDetails,
} from "pages/reporting";
import { Confirmation } from "pages/reporting/Confirmation";
import { CheckUser } from "pages/reporting/CheckUser";
import { ContactInformationAccidentHistoryReport } from "pages/accidentHistoryReport/ContactInformationAccidentHistoryReport";
import { ContactInformationChangeAccidentHistoryReport } from "pages/accidentHistoryReport/ContactInformationChangeAccidentHistoryReport";
import { VehicleSelectionAccidentHistoryReport } from "pages/accidentHistoryReport/VehicleSelectionAccidentHistoryReport";
import { VehicleSelectionConfirmationAccidentHistoryReport } from "pages/accidentHistoryReport/VehicleSelectionConfirmationAccidentHistoryReport";
import { PaymentConfirmationAccidentHistoryReport } from "pages/accidentHistoryReport/PaymentConfirmationAccidentHistoryReport";
import { MyinfoErrorPage } from "pages/accidentHistoryReport/MyinfoErrorPage";
import { CallbackAccidentHistoryReport } from "pages/accidentHistoryReport/CallbackAccidentHistoryReport";
import { CallbackAccidentReporting } from "pages/reporting/CallbackReporting";
import { HomeAccidentHistoryReport } from "pages/accidentHistoryReport/HomeAccidentHistoryReport";
import { RequiredAuthentication } from "components/RequiredAuthentication";

export const routes = {
  newReport: "/new",
  personalInfo: "/personal",
  contactInfo: "/contact",
  vehicleInfo: "/vehicle",
  photoUpload: "/photo",
  insurerInfo: "/insurer",
  reviewMyInfoDetails: "/review-myinfo",
  reviewReportDetails: "/review-report",
  submitConfirmation: "/confirmation",
  checkUser: "/checkuser",
  callback: "/callback",
  loginCallback: "/login/callback",
  accidentHistoryReport: "/accident-history-report",
  accidentHistoryReportPersonalInfo: "/accident-history-report/personal",
  accidentHistoryReportContactInfo: "/accident-history-report/contact",
  accidentHistoryReportChangeContactInfo: "/accident-history-report/contact-change",
  accidentHistoryReportVehicleInfo: "/accident-history-report/vehicle",
  accidentHistoryReportReviewSelection: "/accident-history-report/review-selection",
  accidentHistoryReportSubmitConfirmation: "/accident-history-report/confirmation",
  accidentHistoryReportCheckUser: "/accident-history-report/checkuser",
  accidentHistoryReportCallback: "/accident-history-report/callback",
  accidentHistoryPurchase: "/accident-history-report/purchase",
  accidentHistoryReportVehicleSelection: "/accident-history-report/vehicle-selection",
  accidentHistoryReportVehicleSelectionConfirmation: "/accident-history-report/vehicle-selection-confirmation",
  accidentHistoryReportPaymentConfirmation: "/accident-history-report/payment-confirmation",
  accidentHistoryErrorPage: "/accident-history-report/error-page",
} as const;

const Routing = () => {
  return (
    <Switch>
      <Route path="/" exact={true} component={Home}  />
      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryReport} exact={true} component={HomeAccidentHistoryReport} />}
      <Route path={routes.loginCallback} component={LoginCallback} /> 
      <Route path={routes.callback} component={CallbackAccidentReporting} />
      <SecureRoute path={routes.checkUser} component={CheckUser} />
      <SecureRoute path={routes.newReport} component={NewReport} />
      <SecureRoute path={routes.personalInfo} component={PersonalInformation} />
      <SecureRoute path={routes.contactInfo} component={ContactInformation} />
      <SecureRoute path={routes.vehicleInfo} component={VehicleInformation} />
      <SecureRoute path={routes.photoUpload} component={PhotoUpload} />
      <SecureRoute path={routes.insurerInfo} component={InsurerInformation} />
      <SecureRoute path={routes.reviewMyInfoDetails} component={ReviewMyInfoDetails} />
      <SecureRoute path={routes.reviewReportDetails} component={ReviewReportDetails} />
      <SecureRoute path={routes.submitConfirmation} component={Confirmation} />

      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryReportCallback} component={CallbackAccidentHistoryReport} />}
      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryReportContactInfo}><RequiredAuthentication><ContactInformationAccidentHistoryReport/></RequiredAuthentication></Route>}
      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryReportChangeContactInfo}><RequiredAuthentication><ContactInformationChangeAccidentHistoryReport/></RequiredAuthentication></Route>}
      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryReportVehicleSelection}><RequiredAuthentication><VehicleSelectionAccidentHistoryReport/></RequiredAuthentication></Route>}
      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryReportVehicleSelectionConfirmation}><RequiredAuthentication><VehicleSelectionConfirmationAccidentHistoryReport/></RequiredAuthentication></Route>}
      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryReportPaymentConfirmation}><RequiredAuthentication><PaymentConfirmationAccidentHistoryReport/></RequiredAuthentication></Route>}
      {config.accidentHistoryReportModule && <Route path={routes.accidentHistoryErrorPage} component={MyinfoErrorPage} />}
    </Switch>
  )
};
export default Routing;
