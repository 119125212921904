import React from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { PageTitle } from "./PageTitle";
import { PageText } from "./PageText";
import { Grid, GridItem } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { CloseLightSvg } from "@shift/gears-icons";
import { colors } from "constants/colors";

interface ModalProps{
    title: string,
    message: string | undefined,
    subMessage: string | undefined,
    button: React.FC | JSX.Element,
    showDialog: boolean,
    showDialogHandler: (displayDialog :boolean) => void;
}

export const AlertDialog = ({title, message, subMessage, button, showDialog, showDialogHandler} : ModalProps) => {
    const dialogRootEl = document.getElementById('dialogs');

    return showDialog && 
        dialogRootEl 
        ? createPortal(
            <>
            <Backdrop/>
            <Modal>                
                <Spacing>
                    <Grid direction="row">                            
                        <GridItem flex={100}>
                            <PageTitle>
                                {title}
                            </PageTitle>
                        </GridItem>
                        <GridItem flex={1} onClick={() => showDialogHandler(false)}>
                            <StyledCloseIcon withDefault={false}/>
                        </GridItem>                            
                    </Grid>
                </Spacing>                            
                <HorizontalLine />
                <ContentWrapper>
                    <Text>
                        {message}
                    </Text>                                               
                    {button}                        
                    <Text>
                        {subMessage}
                    </Text>                                
                </ContentWrapper>                                 
            </Modal>
            </>,
            document.getElementById('dialogs')!
        ) : null;
}

const Modal = styled.div`
    position: fixed;
    top: 25vh;
    left: 50%;
    transform: translate(-50%);
    text-align: left;
    background-color: ${colors.background?.white};
    height: 50%;
    border-radius: 5px;
    box-shadow: 0px 2px 10px;
    width: 500px;

    @media (max-width: 548px) {
        width: 90%;
    }
`;

const Spacing = styled.div`
    padding-left: 30px;
    padding-right: 30px;
`

const Text = styled(PageText)`
    padding: 15px 5px 5px 5px;    
`

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);    
`

const StyledCloseIcon = styled(CloseLightSvg)`
    position: relative;
    font-size: 1.5em;
    padding: 15px;
    margin-top: 5px;
    cursor: pointer;
`

const HorizontalLine = styled.div`
    border-bottom: 1px solid ${colors.border?.default};
`