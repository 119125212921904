import React from "react";
import styled from "styled-components";
import { colors } from "constants/colors";

const Styled = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${(_) => colors.brand?.primary?.base};
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: ${(_) => colors.brand?.primary?.base};
  padding: 10px;
  cursor: pointer;
  width: auto;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;

  &:hover {
    filter: opacity(0.9);
  }
`;

interface IPhotoUploadButton {
  children: React.ReactNode;
  multiSelection?: boolean;
  photoHandler: (photos: File[]) => void
}

export const PhotoUploadButton: React.FC<IPhotoUploadButton> = ({ children, multiSelection, photoHandler }) => {

  const photoHandlerInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      photoHandler(Array.from(e.target.files));
    }
  }

  return (
    <label htmlFor="cameraFileInput">
      <Styled className="btn">{children}</Styled>
      <input
        id="cameraFileInput"
        type="file"
        accept="image/*"
        capture="environment"
        style={{ display: "none" }}
        multiple={multiSelection}
        onChange={photoHandlerInternal}
      />
    </label>
  );
};
