import { colors } from "constants/colors";
import React from "react";
import { PageText } from "./PageText";
import checkIcon from "resources/check.png";

interface IStepProps {
  stepId: number;
  children: React.ReactNode;
  done?: Boolean;
}

export const Step: React.FC<IStepProps> = ({ children, stepId, done = false }) => (
  <div style={{ margin: "10px 10px 5px 10px" }}>
    <span
      style={{
        color: done ? colors.custom.success : colors.text?.information,
        fontWeight: "600",
      }}
    >
      {done && <img src={checkIcon} alt="done" />}
      {` Step ${stepId}. `}
    </span>
    <span
      style={{
        fontWeight: "600",
        textDecoration: done ? "line-through" : "none",
      }}
    >
      {children}
    </span>
  </div>
);

export const StepDescription: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div style={{ margin: "0 11px 0 11px" }}>
    <PageText>{children}</PageText>
  </div>
);
