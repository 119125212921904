import React, { useEffect, useMemo } from "react";
import { ReportPageWrapper } from "components";
import { routes } from "entrypoint/Routing";
import { useAppState } from "state";
import { IKeyValuePairBoldedProps, PanelWithEdit } from "./commonComponents";
import { useSubmitReport } from "hooks/useSubmitReport";
import { misc } from "constants/misc";
import { useNextRoute } from "hooks";
import { dateUtils } from "utils/dates";

export const ReviewReportDetails = () => {
  const { reportState, updateReportState } = useAppState();
  const { goToNextRoute } = useNextRoute("submitConfirmation");

  const { contactInfo, insurerInfo, attachments, vehicleInfo, isVehicleOwner } = reportState;

  const [submitToGears, submitting, submitData, submitErrors] = useSubmitReport(reportState);

  useEffect(() => {
    const reportId = submitData?.insertAccidentReport?.generalInformation?.accidentInformation.referenceCode;

    if (reportId) {
      if (submitErrors) submitErrors.unshift("Report has been saved, however some erorrs occured:");
      updateReportState({ submitInfo: { date: dateUtils.getCurrentSingaporeDateTime().toString(), reportId, errors: submitErrors } });
      goToNextRoute();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitData, submitErrors]);

  const contactSection: IKeyValuePairBoldedProps[] = [
    { label: "Phone number", value: `+${contactInfo?.phone?.dialCode} ${contactInfo?.phone?.number}` },
    { label: "Email", value: contactInfo?.email },
  ];

  const insurerSection: IKeyValuePairBoldedProps[] = [
    { label: "Handling insurer", value: insurerInfo?.insurer?.label },
    { label: "Authorised reporting center", value: insurerInfo?.arc?.arcName },
  ];

  const photosSection: IKeyValuePairBoldedProps[] | undefined = useMemo(
    () => attachments?.map((x) => ({ label: x.name, value: x.thumbnailSrc ?? misc.noImageUrl })),
    [attachments]
  );

  const vehicleSection: IKeyValuePairBoldedProps[] = [{ label: "Vehicle registration number", value: vehicleInfo?.vehicleno }];

  return (
    <ReportPageWrapper
      stepNumber={7}
      pageTitle="Review the information you provided"
      onSubmitFn={submitToGears}
      submitButtonText="Submit report"
      nextRoute="submitConfirmation"
      submitting={submitting}
      submitEnabled={!submitting}
      blockNavigation
    >
      <PanelWithEdit data={contactSection} editRoute={routes.contactInfo} title="Contact" />
      <PanelWithEdit data={photosSection} editRoute={routes.photoUpload} title="Photos and Videos" isImg />
      {!isVehicleOwner && <PanelWithEdit data={vehicleSection} editRoute={routes.vehicleInfo} title="Vehicle" />}
      <PanelWithEdit data={insurerSection} editRoute={routes.insurerInfo} title="Insurer" />
    </ReportPageWrapper>
  );
};
