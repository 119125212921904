import { StyledText } from "@shift/gears-design-system";
import { colors } from "constants/colors";
import React from "react";
import styled from "styled-components";

const Content = styled.div`
  line-height: 20.8px;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const PageText = ({ children, className }: { children: React.ReactNode, className?: any }) => {
  return (
    <StyledText type="div" size="medium" color={colors.text?.header}>
      <Content className={className}>{children}</Content>
    </StyledText>
  );
};
