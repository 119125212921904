export interface BillableItem{
    gstInclusivePrice: number;
    gst: number;
    price: number;
};

export const mapBillableItem = (response: any) => {
    return {
        gstInclusivePrice: response.gstInclusivePrice,
        gst: response.gst,
        price: response.price
    }
};