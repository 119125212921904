import React, { useContext, useEffect, useState } from "react";
import { CircularLoader, Grid, GridItem, ThemeContext } from "@shift/gears-design-system";
import { PageHeader } from "components/PageHeader";
import { ContentWrapper } from "styles/Style.Media";
import { PageTitle } from "components/PageTitle";
import { PageText } from "components/PageText";
import { SecondaryTextButton } from "components/SecondaryButton";
import styled from "styled-components";
import config from "configuration/config";
import { useAppState } from "state";
import logo from "resources/myinfo_retrival.png";
import { ImageButton } from "components";
import { AlertDialog } from "components/AlertModal";
import { routes } from "entrypoint/Routing";
import { useErrorMessageContext } from "components/ErrorMessageProvider";

const StyledLogOutButton = styled(SecondaryTextButton)`
  border-color: #bdbdbd;
  color: rgba(51, 51, 51, 1);
  margin-top: 24px;
  margin-bottom: 12px;
`;

const authState = Math.floor(Math.random() * 100000);

const authoriseUrl =
  config.myInfo.authUrl +
  "?client_id=" +
  config.myInfo.clientId +
  "&attributes=" +
  config.myInfo.attributes +
  "&purpose=" +
  config.myInfo.purpose +
  "&state=" +
  encodeURIComponent(authState) +
  "&redirect_uri=" +
  config.myInfo.redirectUrl;

export const PersonalInformation = (props: any) => {
  const { reportState: { isVehicleOwner, isVehicleDriver }, updateReportState } = useAppState();
  const [selection, setSelection] = useState({ isVehicleOwner, isVehicleDriver });
  const [redirecting, setRedirecting] = useState(false);
  const { colors } = useContext(ThemeContext);
  const [showDialog, setShowDialog] = useState(props.location.state?.referrer === routes.callback);
  const { displayMessage } = useErrorMessageContext();

  useEffect(() => {
    if (props.location.state?.error) {
      displayMessage([props.location.state?.error])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = () => {
    setRedirecting(true);
    updateReportState({ ...selection });
    window.location.href = authoriseUrl;
  };

  if (redirecting)
    return (
      <div style={{ margin: "auto" }}>
        <CircularLoader color={colors.brand.primary.base} width="16px" size="100px" />
      </div>
    );

  const isOptionSelected = selection.isVehicleDriver || selection.isVehicleOwner;

  return (
    <>
      <PageHeader />
      <ContentWrapper>
        <Grid direction="column" style={{ minHeight: "100%" }}>
          <GridItem flex={1}>
            <PageTitle>Personal information</PageTitle>
            <PageText>
              We will automatically fill your personal information using Singpass’s Myinfo solution. By selecting one of the options
              below and pressing "Retrieve Myinfo with Singpass" you will be redirected to Myinfo’s verification page.
            </PageText>
          </GridItem>
          <GridItem flex={100} style={{ display: "flex", flexDirection: "column" }}>
            <StyledLogOutButton onClick={() => setSelection({ isVehicleDriver: true, isVehicleOwner: true })}>
              I am the driver and owner of the vehicle
            </StyledLogOutButton>
            <StyledLogOutButton onClick={() => setSelection({ isVehicleDriver: true, isVehicleOwner: false })}>
              I am only the driver
            </StyledLogOutButton>
            <StyledLogOutButton onClick={() => setSelection({ isVehicleDriver: false, isVehicleOwner: true })}>
              I am only the owner
            </StyledLogOutButton>
          </GridItem>
          <GridItem flex={1} style={{ display: "inline-grid" }}>
            {isOptionSelected && <ImageButton src={logo} onClick={() => submit()} alt="my info login" />}
            <br />
          </GridItem>
        </Grid>
      </ContentWrapper>
      <AlertDialog title="Information"
        message="Connection to Myinfo is required to report an accident on this site."
        subMessage="If you don't wish to provide access to your Myinfo data, please go directly to an Authorised Reporting Centre to declare your accident."
        button={<ImageButton src={logo} onClick={() => submit()} alt="my info login" />}
        showDialog={showDialog}
        showDialogHandler={setShowDialog}
      />
    </>
  );
};
