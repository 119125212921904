import { Colors } from "@shift/gears-design-system";

interface CustomColors extends Colors {
  custom: {
    success: string;
  };
}

// Spec: https://www.figma.com/file/pQ2ZOuSpARdOUbARWqHkz1/FORCE_Design_System_DRAFT?node-id=682%3A260
export const colors: CustomColors = {
  brand: {
    primary: {
      lighten8: "#DF793A",
      lighten4: "#E16A23",
      base: "#D86018",
      darken4: "#CA5712",
      darken8: "#BB4E0C",
    },
    secondary: {
      lighten8: "#225A7C",
      lighten4: "#1B4F6F",
      base: "#14435F",
      darken4: "#0F3952",
      darken8: "#0A2D42",
    },
  },
  action: {
    primary: {
      default: "#D86018",
      hover: "#E16A23",
      active: "#BB4E0C",
      selected: "#CA5712",
      focused: "#D86018",
    },
    secondary: {
      default: "#14435F",
      hover: "#1B4F6F",
      active: "#0A2D42",
      selected: "#0F3952",
      focused: "#5134C9",
    },
  },
  text: {
    interactive: "#14435F",
    button: "#00727A",
    secondary: "#9E9E9E",
    information: "#D86018",
    header: "#333333",
  },
  border: {
    default: "#BDBDBD",
  },
  background: {
    primary: "rgba(216,96,24,0.1)",
    white: "#FFFFFF",
  },
  custom: {
    success: "#1F9E59",
  },
};
