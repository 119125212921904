import React, { useContext, useEffect, useState } from "react";
import { Button, CircularLoader, Grid, GridItem, ThemeContext } from "@shift/gears-design-system";
import { useOktaAuth } from "@okta/okta-react";
import { PageHeader } from "components/PageHeader";
import { ContentWrapper } from "styles/Style.Media";
import { PageTitle } from "components/PageTitle";
import { PageText } from "components/PageText";
import { useHistory } from "react-router-dom";
import { routes } from "entrypoint/Routing";
import { useAppState } from "state";
import { useClaimSearchResult } from "hooks";
import { ClaimResultCard } from "components/ClaimResultCard";
import styled from "styled-components";

const Content = styled.div`
  line-height: 20.8px;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const NewReport = (props: any) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<any>(null);
  const history = useHistory();
  const { clearReportState } = useAppState();

  const { colors } = useContext(ThemeContext);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info: any) => {
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const onClick = () => {
    clearReportState();
    history.push(routes.personalInfo);
  };

  const { loading: loadingClaimSearchResult, matchedReport } = useClaimSearchResult();
  return (
    <>
      {authState?.isAuthenticated && !userInfo && (
        <div style={{ margin: "auto" }}>
          <CircularLoader color={colors.brand.primary.base} width="16px" size="100px" />
        </div>
      )}
      {authState?.isAuthenticated && userInfo && (
        <Grid direction="column" style={{ minHeight: "100%" }}>
          <GridItem>
            <PageHeader hideBackButton />
          </GridItem>
          <GridItem flex={100} style={{ display: "table" }}>
            <ContentWrapper>
              <Grid direction="column" style={{ minHeight: "100%" }}>
                <GridItem flex={100} style={{ display: "table" }}>
                  <GridItem>
                    <PageTitle>GEARS Online Accident Notification</PageTitle>
                    <PageText>You can create a new accident report or check a previous one.</PageText>
                  </GridItem>
                  <GridItem style={{ display: "inline-grid" }}>
                    {matchedReport.length > 0 && <PageTitle>{`Accidents notified`}</PageTitle>}
                    <Content>
                      {loadingClaimSearchResult ? (
                        <CircularLoader color={colors.brand.primary.base} width="10px" size="50px" />
                      ) : (
                        matchedReport.map((item, index) => <ClaimResultCard key={index} claim={item} />)
                      )}
                    </Content>
                  </GridItem>
                </GridItem>
              </Grid>
            </ContentWrapper>
          </GridItem>
          <GridItem flex={1} style={{ display: "inline-grid" }}>
            <ContentWrapper>
              <Button onClick={onClick}>I want to report a new accident</Button>
              <br />
            </ContentWrapper>
          </GridItem>
        </Grid>
      )}
    </>
  );
};
