import config from "configuration/config";
import { DocumentCategory, UploadSource } from "types";

export const genericErrorMessage = "Failed to upload file, please remove it and add again."

export const uploadFile = (
  file: File,
  onProgress: (progress: number) => void,
  onFinish: (id: string) => void,
  onError: (errorMessage: string) => void,
  documentCategory: DocumentCategory = DocumentCategory.UnClassified,
  token: () => Promise<string | null>
) => {
  const xhr = new XMLHttpRequest();
  token().then(
    (t) => {
      xhr.upload.onprogress = (e) => onProgress((e.loaded / e.total) * 100);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            onFinish(xhr.response);
          } else {
            onError(xhr.response);
          }
        }
      };
      xhr.onerror = () => {
        onError(genericErrorMessage);
      };
      xhr.open("POST", config.resourceServer.api.documentUploadUri, true);
      console.log(config.resourceServer.api.documentUploadUri);
      if (t) {
        xhr.setRequestHeader("Authorization", `Bearer ${t}`);
      }
      xhr.setRequestHeader("X-FileName", encodeURI(file.name)); // Pass the filename along
      const formData = new FormData();
      formData.append("file", file);
      formData.append("documentCategory", documentCategory.toString());
      formData.append("uploadSource", UploadSource.MobileUpload.toString());
      xhr.send(formData);
    },
    () => onError(genericErrorMessage)
  );
  return () => xhr.abort();
};
