import { IReportState } from "state";
import { mapMake, mapMakeAHR, mapModel, mapModelAHR, mapToUtcDate, mapVehicle } from "./mapVehicle";
import { mapIdentifierType, mapFullAddress, mapGender, mapDate, mapIsLicenceRevoked, mapAttachments, mapDrivingLicenses, mapLicenseValidity } from "./mappers";


interface IMapping {
  gearsPath: string;
  value: any;
  mappingFn?: Function;
  onlyIf?: Boolean;
}

export const getMapping = (reportState: IReportState): IMapping[] => [
  {
    gearsPath: "ownVehicle.claimant",
    value: reportState,
    mappingFn: (rs: IReportState) => `Claimant.${isBoth(rs) ? "Both" : isOnlyDriver(rs) ? "Driver" : "Owner"}`,
  },
  {
    gearsPath: "ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_VehicleRegistration",
    value: reportState.vehicleInfo?.vehicleno,
    mappingFn: (value: string) => [{ number: value }],
  },
  {
    gearsPath: "ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle",
    value: reportState,
    mappingFn: mapVehicle,
  },
  {
    gearsPath: "ownVehicle.vehiclePolicy.policy.specializations.mapping_Policy_Insurer.insurer",
    value: reportState.insurerInfo?.insurer?.value,
  },
  {
    gearsPath: "ownVehicle.owner.person.specializations.mapping_Person_Gears.name",
    value: reportState.personalInfo?.name,
    onlyIf: reportState.isVehicleOwner,
  },
  {
    gearsPath: "ownVehicle.owner.person.specializations.[mapping_Person_NationalIdentifier].0.identifier",
    value: reportState.personalInfo?.ufin,
    onlyIf: reportState.isVehicleOwner,
    mappingFn: (value: string) => `NationalIdentifierType.${mapIdentifierType(value)}`,
  },
  {
    gearsPath: "ownVehicle.owner.person.specializations.[mapping_Person_NationalIdentifier].0.nationalIdentifier",
    value: reportState.personalInfo?.ufin,
    onlyIf: reportState.isVehicleOwner,
  },
  {
    gearsPath: "ownVehicle.owner.email.address",
    value: reportState.contactInfo?.email,
    onlyIf: reportState.isVehicleOwner,
  },
  {
    gearsPath: "ownVehicle.owner.primaryPhoneNumber.number",
    value: reportState.contactInfo?.phone?.number,
    onlyIf: reportState.isVehicleOwner,
  },
  {
    gearsPath: "ownVehicle.owner.primaryPhoneNumber.countryCode",
    value: reportState.contactInfo?.phone?.dialCode,
    onlyIf: reportState.isVehicleOwner,
  },
  {
    gearsPath: "ownVehicle.owner.primaryPhoneNumber.phoneNumberType",
    value: reportState.contactInfo?.phone?.number,
    onlyIf: reportState.isVehicleOwner,
    mappingFn: () => "PhoneNumberType.Phone",
  },
  {
    gearsPath: "ownVehicle.driverIsSameAsOwner",
    value: isBoth(reportState),
  },
  {
    gearsPath: "ownVehicle.driver.address.fullAddress",
    value: reportState.regAdd,
    mappingFn: mapFullAddress,
    onlyIf: reportState.isVehicleDriver,
  },
  {
    gearsPath: "ownVehicle.driver.person.specializations.mapping_Person_Individual.gender",
    value: reportState.personalInfo?.sex,
    mappingFn: mapGender,
    onlyIf: reportState.isVehicleDriver,
  },
  {
    gearsPath: "ownVehicle.driver.person.specializations.mapping_Person_Individual.dateOfBirth",
    value: reportState.personalInfo?.dob,
    mappingFn: mapDate,
    onlyIf: reportState.isVehicleDriver,
  },
  {
    gearsPath: "ownVehicle.driver.person.specializations.mapping_Person_Gears.name",
    value: reportState.personalInfo?.name,
    onlyIf: isOnlyDriver(reportState),
  },
  {
    gearsPath: "ownVehicle.driver.person.specializations.[mapping_Person_NationalIdentifier].0.identifier",
    value: reportState.personalInfo?.ufin,
    onlyIf: isOnlyDriver(reportState),
    mappingFn: (value: string) => `NationalIdentifierType.${mapIdentifierType(value)}`,
  },
  {
    gearsPath: "ownVehicle.driver.person.specializations.[mapping_Person_NationalIdentifier].0.nationalIdentifier",
    value: reportState.personalInfo?.ufin,
    onlyIf: isOnlyDriver(reportState),
  },
  {
    gearsPath: "ownVehicle.driver.primaryPhoneNumber.number",
    value: reportState.contactInfo?.phone?.number,
    onlyIf: isOnlyDriver(reportState),
  },
  {
    gearsPath: "ownVehicle.driver.primaryPhoneNumber.countryCode",
    value: reportState.contactInfo?.phone?.dialCode,
    onlyIf: isOnlyDriver(reportState),
  },
  {
    gearsPath: "ownVehicle.driver.primaryPhoneNumber.phoneNumberType",
    value: reportState.contactInfo?.phone?.number,
    onlyIf: isOnlyDriver(reportState),
    mappingFn: () => "PhoneNumberType.Phone",
  },
  {
    gearsPath: "ownVehicle.driver.email.address",
    value: reportState.contactInfo?.email,
    onlyIf: isOnlyDriver(reportState),
  },
  {
    gearsPath: "ownVehicle.driver.person.specializations.[mapping_Person_DrivingLicenseInformation]",
    value: reportState.licenceInfo,
    onlyIf: isDriver(reportState),
    mappingFn: mapDrivingLicenses
  },
  {
    gearsPath: "ownVehicle.driverLicence",
    value: reportState.licenceInfo,
    mappingFn: mapLicenseValidity
  },
  {
    gearsPath: "generalInformation.accidentInformation.specializations.mapping_Claim_FreeText.eventDescription",
    value: reportState.comment,
  },
  {
    gearsPath: "attachments.accidentOrVehiclePhotos",
    value: reportState.attachments,
    mappingFn: mapAttachments('Image'),
  },

  {
    gearsPath: "attachments.accidentVideos",
    value: reportState.attachments,
    mappingFn: mapAttachments('Video'),
  },

  {
    gearsPath: "generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyAccidentPhoto",
    value: reportState.attachments,
    mappingFn: (attachments: typeof reportState.attachments) => {
      var mappedAttachments = mapAttachments('Image')(attachments);
      return attachments && mappedAttachments !== undefined && mappedAttachments.length > 0 ? true : false;
    }
  },
  {
    gearsPath: "generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyAccidentVideo",
    value: reportState.attachments,
    mappingFn: (attachments: typeof reportState.attachments) => {
      var mappedAttachments = mapAttachments('Video')(attachments);
      return attachments && mappedAttachments !== undefined && mappedAttachments.length > 0 ? true : false;
    }
  }
];

export const getAccidentHistoryReportMapping = (reportState: IReportState): IMapping[] => [
  {
    gearsPath: "vrn",
    value: reportState.selectedVehicle?.vehicleno,

  },
  {
    gearsPath: "vin",
    value: reportState.selectedVehicle?.chassisno,

  },
  {
    gearsPath: "vehicleMakeInfo",
    value: reportState.selectedVehicle,
    mappingFn: mapMakeAHR,

  },
  {
    gearsPath: "model",
    value: reportState.selectedVehicle,
    mappingFn: mapModelAHR,

  },
  {
    gearsPath: "firstRegistrationDate",
    value: reportState.selectedVehicle?.firstregistrationdate,
    mappingFn: mapToUtcDate,

  },
  {
    gearsPath: "email",
    value: reportState.contactInfo?.email,

  },
  {
    gearsPath: 'nric', 
    value: reportState.personalInfo?.ufin,
  }

]
const isOnlyDriver = ({ isVehicleDriver, isVehicleOwner }: IReportState) => isVehicleDriver && !isVehicleOwner;
const isBoth = ({ isVehicleDriver, isVehicleOwner }: IReportState) => isVehicleDriver && isVehicleOwner;
const isDriver = ({ isVehicleDriver }: IReportState) => isVehicleDriver;

