import { CircularLoader, SubmitButton, ThemeContext } from "@shift/gears-design-system";
import { routes } from "entrypoint/Routing";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { useCreateUser } from "hooks/useCreateUser";
import { PageHeader } from "components";
import { ContentWrapper } from "styles/Style.Media";

export const CheckUser = () => {
  const { data, error, repeat } = useCreateUser();
  const { colors } = useContext(ThemeContext);
  
  const noResponse = data == null && error == null;
  return (
    <>
      {noResponse ? (
        <div style={{ margin: "auto" }}>
          <CircularLoader color={colors.brand.primary.base} width="16px" size="100px" />
        </div>
      ) : error || !data?.createUser ? (
        <>
          <PageHeader hideBackButton />
          <ContentWrapper>
            <p>Problem with user validation has occurred, please try again later.</p>
            <p>Error description: {error?.message || "Unable to validate account"}</p>
            <SubmitButton onClick={() => repeat()}>Try again</SubmitButton>
          </ContentWrapper>
        </>
      ) : (
          <Redirect to={routes.newReport} />
      )}
    </>
  );
};
