import { Stepper } from "@shift/gears-design-system";
import React, { memo } from "react";

const stepsTemplate = Array(7).fill(null);

const PageStepperInternal = memo(({ currentStep }: { currentStep: number }) => {
  const steps = stepsTemplate.map((x, index) => ({ label: "", isDone: index + 1 < currentStep }));
  return <Stepper steps={steps}></Stepper>;
});

PageStepperInternal.displayName = "PageStepper";

export const PageStepper = PageStepperInternal;
