import React, { useState } from "react";
import { SelectInput, SelectInputOption, TextInput } from "@shift/gears-design-system";
import { ReportPageWrapper, PaddedInputForm } from "components";
import { useAppState } from "state";
import { IValidationError, getError, validateVehicleNumber } from "utils/validators";

const isSelectInputOption = (value: any): value is SelectInputOption => {
  return Array.isArray(value) ? false : true;
};

const otherOption = { label: "Other", value: "other" };

export const VehicleInformation = () => {
  const {
    reportState: { vehicleInfo, ownedVehicles, isVehicleOwner },
    updateReportState,
  } = useAppState();

  const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);

  const selectionValues: SelectInputOption[] = (
    ownedVehicles?.filter((x) => !!x.vehicleno).map<SelectInputOption>((x) => ({ label: x.vehicleno!, value: x.vehicleno! })) ??
    []
  ).concat([otherOption]);

  const [vrnDropdownValue, setVrnDropdownValue] = useState<SelectInputOption | undefined>(
    selectionValues.find((x) => x.value === vehicleInfo?.vehicleno) ?? (vehicleInfo?.vehicleno ? otherOption : undefined)
  );

  const [vrnTextValue, setVrnTextValue] = useState<string | undefined>(
    vrnDropdownValue === otherOption ? vehicleInfo?.vehicleno : undefined
  );

  const onSubmit = () => {
    updateReportState({
      vehicleInfo: isVehicleOwner && vrnDropdownValue !== otherOption
        ? ownedVehicles?.find((x) => x.vehicleno === vrnDropdownValue?.value)
        : { vehicleno: vrnTextValue },
    });
  };

  const onVrnSelection = (e: SelectInputOption[]) => {
    if (isSelectInputOption(e)) {
      setVrnDropdownValue(e);
    }
  };

  const isFormValid = () => {
    const validationErrors: IValidationError[] = [];

    validationErrors.push({ field: "vrn", error: vrnDropdownValue === otherOption ? validateVehicleNumber(vrnTextValue)?undefined: "Vehicle Registration Number (VRN) is not valid" : undefined  });

    if (validationErrors.find((x) => !!x.error)) {
      setValidationErrors(validationErrors);
      return false;
    }

    return true;
  }

  const valuesProvided = isVehicleOwner
    ? vrnDropdownValue === otherOption
      ? !!vrnTextValue
      : !!vrnDropdownValue
    : !!vrnTextValue;

  const isVehicleOwnerDescription = "Select the vehicle registration number of the vehicle that was involved in the accident.";
  const isNotVehicleOwnerDescription = "Please indicate the Vehicle Registration Number (VRN) of the damaged vehicle. ";

  return (
    <ReportPageWrapper
      stepNumber={3}
      pageTitle="Vehicle Information"
      pageDescription={isVehicleOwner ? isVehicleOwnerDescription : isNotVehicleOwnerDescription}
      onSubmitFn={onSubmit}
      submitEnabled={valuesProvided}
      nextRoute="photoUpload"
      isFormValid={isFormValid}
    >
      <PaddedInputForm label="Vehicle registration number" error={getError("vrn", validationErrors)}>
        <>
          {(isVehicleOwner) && (
            <SelectInput options={selectionValues} onChange={onVrnSelection} value={vrnDropdownValue ? [vrnDropdownValue] : undefined} />
          )}
          {(!isVehicleOwner || vrnDropdownValue === otherOption) &&
            <TextInput placeholder="ex: SBAI345B" value={vrnTextValue || ""} onChange={(e) => setVrnTextValue(e.toLocaleUpperCase().trim())} />
          }
        </>
      </PaddedInputForm>
    </ReportPageWrapper>
  );
};
