import React, { useEffect, useState } from "react";
import { PhoneInput, TextInput } from "@shift/gears-design-system";
import { PageSectionTitle, ReportPageWrapper, PaddedInputForm } from "components";
import { useHistory } from "react-router-dom";
import { routes } from "entrypoint/Routing";
import { getError, IValidationError, validateEmail } from "utils/validators";
import { useAppState } from "state";
import { queryStringParams, useQueryStringParams } from "hooks";

export const ContactInformation = () => {
  const {
    reportState: { contactInfo, isVehicleDriver },
    updateReportState,
  } = useAppState();
  
  const queryParams = useQueryStringParams();
  const history = useHistory();
  
  const [state, setState] = useState(contactInfo ?? {});
  const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);

  useEffect(() => {
    if (!contactInfo?.phone?.dialCode) {
      setState({email: contactInfo?.email, phone: { dialCode: "65", countryCode: "SG", number: contactInfo?.phone?.number}})
    }
  },[contactInfo])

  const isFormValid = () => {
    const validationErrors: IValidationError[] = [];

    validationErrors.push({ field: "email", error: validateEmail(state.email) ? undefined : "Email is not valid" });
    validationErrors.push({
      field: "phone",
      error: state.phone?.countryCode && state.phone?.number ? undefined : "Phone number is required",
    });

    if (validationErrors.find((x) => !!x.error)) {
      setValidationErrors(validationErrors);
      return false;
    }

    return true;
  }

  const onSubmit = () => {
    updateReportState({ contactInfo: state });
  };

  const valuesProvided = !!(state && state.phone?.countryCode && state.phone?.number && state.email);

  return (
    <ReportPageWrapper
      stepNumber={2}
      pageTitle="Contact Information "
      pageDescription="Your email address is used to send you the confirmation so make sure it is correct."
      onSubmitFn={onSubmit}
      submitEnabled={valuesProvided}
      alternativeOnBackFn={() => queryParams.get(queryStringParams.fromReview) ? history.goBack() : history.push(routes.newReport)}
      nextRoute={"vehicleInfo"}
      isFormValid={isFormValid}
    >
      <PageSectionTitle>{isVehicleDriver ? "Driver" : "Owner"}</PageSectionTitle>
      <PaddedInputForm label="Phone number" error={getError("phone", validationErrors)}>
        <PhoneInput
          value={state.phone}
          onChange={(e) => setState((prev) => ({ ...prev, phone: e }))}
          width="145px"
          disableAutoComplete
        ></PhoneInput>
      </PaddedInputForm>
      <PaddedInputForm label="Email" error={getError("email", validationErrors)}>
        <TextInput onChange={(e) => setState((prev) => ({ ...prev, email: e }))} value={state.email}></TextInput>
      </PaddedInputForm>
    </ReportPageWrapper>
  );
};
