import React, { createContext, useCallback, useContext, useState } from "react";
import styled from "styled-components";

interface ModalContext {
  displayMessage: (message: string[]) => void;
  clearMessage: () => void;
}

export const ErrorMessageContext = createContext<ModalContext>({ displayMessage: () => null, clearMessage: () => null });

export const useErrorMessageContext = () => useContext(ErrorMessageContext);

export const ErrorMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const [messages, setMessage] = useState<string[] | null>(null);
  const displayMessage = useCallback((msg: string[]) => setMessage(msg), []);
  const clearMessage = useCallback(() => setMessage(null), []);

  return (
    <>
      {messages && (
        <ErrorsPanel>
          <b>Some errors occured:</b>
          <ul>
            {messages?.map((message, i) => (
              <li key={i}>{message}</li>
            ))}
          </ul>
        </ErrorsPanel>
      )}
      <ErrorMessageContext.Provider value={{ displayMessage, clearMessage }}>{children}</ErrorMessageContext.Provider>
    </>
  );
};

const ErrorsPanel = styled.div`
  background-color: #c53131;
  color: white !important;
  margin: 0 0 2px 0;
  padding: 15px;
  word-wrap: break-word;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
    margin-top: 5px;
  }
`;
