import { AccessToken } from '@okta/okta-auth-js';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IReportState } from 'state';
import { mapAccidentHistoryReportStateToGears } from 'utils/mapping/mapReportStateToGears';

type FetchType = (input: RequestInfo, init?: RequestInit) => Promise<Response>;
export type DownloadType = (url: string, name?: string | undefined) => Promise<void>;

const context = React.createContext<FetchType | null>(null);

export interface FetchProviderProps {
    token?: () => Promise<AccessToken | null | undefined>;
}
// eslint-disable-next-line no-console
const errorLog = process.env.NODE_ENV === 'production' ? console.warn : console.error;

export const FetchProvider: React.FC<FetchProviderProps> = ({ token, children }) => {
    const fetch: FetchType = React.useCallback(async (input, init) => {
        const inp = new window.Request(input);
        const t = token !== undefined ? await token() : undefined;
        if (t) {
            inp.headers.append('Authorization', `Bearer ${t.accessToken}`);
        }
        return window.fetch(inp, init);
    }, [token]);
    return (
        <context.Provider value={fetch}>
            {children}
        </context.Provider>
    );
};

export const useFetch = ( onUnauthorized: () => void) => {
    const fetch = useContext(context);
    if (!fetch) {
        throw new Error('missing provider');
    }
    const download = React.useCallback<DownloadType>(async (url: string) => {
        const response = await fetch(url, { credentials: 'include' });
        if (response.status !== 200){
            if (response.status === 401){
                onUnauthorized();
            };
            throw new Error('Error while downloading report.');
        } 
        const blob = new window.Blob([await response.blob()]);
        const fileName = response.headers.get('content-disposition')?.split(';')[1].split('filename=')[1]; // Name provided by backend
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
        } else {
            const blobUrl = window.URL.createObjectURL(blob);
            const el = document.createElement('a');
            el.href = blobUrl;
            if (fileName) {
                el.download = fileName; // Override with frontend name
            }
            el.click();
        }
    }, [fetch]);
    return {
        fetch,
        download,
    };
};

export const useGetQuery = <T,>(
    query: string,
    mapFunction: (rawData: any) => T,
    onUnauthorized: () => void
) => {
    const fetch = useContext(context);
    if (!fetch) {
        throw new Error('missing provider');
    }
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(query, { credentials: 'include' });
                if (response.status !== 200){
                    if (response.status === 401){
                        onUnauthorized();
                    };
                    throw new Error('Error while executing the request');
                }          
                const json = await response.json();
                setData(mapFunction(json));
                setLoading(false);
            } catch (err) {
                setLoading(false);
                throw new Error('Error while executing the request.');
            }
        };
        fetchData();
    }, [mapFunction, query]);
    return { data, loading };
};

export const useGetQueryWithRetry = (
    query: string,
    maxRetries: number,
    retryDelayInMs: number,
    condition: (response: any) => boolean,
    onFail: (response: any) => void,
    onSuccess: (response: any) => void,
    onUnauthorized: () => void
) => {
    const fetch = useContext(context);
    if (!fetch) {
        throw new Error('missing provider');
    }

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(query, { credentials: 'include' });

                if (response.status !== 200){
                    if (response.status === 401){
                        onUnauthorized();
                    };
                    throw new Error('Error while executing request.');
                }                
                let json = await response.json();
                let retries = 1;

                while (retries < maxRetries) {
                    if (condition(json)) {
                        onSuccess(json);
                        setLoading(false);
                        return;
                    }
                    else {
                        await new Promise(resolve => setTimeout(resolve, retryDelayInMs));
                        retries++;
                    }
                    response = await fetch(query, { credentials: 'include' });
                    if (response.status !== 200){
                        if (response.status === 401){
                            onUnauthorized();
                        };
                        throw new Error('Error while executing request.');
                    }          
                    json = await response.json();
                }
                onFail(json);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                throw new Error('Error while executing the request.');
            }
        };
        fetchData()
    }, [query]);
    return { loading };
}


export const usePostQuery = <T,>(
    query: string,
    reportState: IReportState,
    onUnauthorized: () => void,
    onSuccess: (response: any) => void,
) => {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const fetch = useContext(context);
    const accidentHistoryReport = useMemo(() => mapAccidentHistoryReportStateToGears(reportState), [reportState]);
    const onSubmit = () => {
   
    if (!fetch) {
        throw new Error('missing provider');
    }
        const fetchData = async () => {
            try {
                var cred: RequestCredentials = "include";
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json; charset = utf-8;',
                        'accept': 'application/json',                        
                    },
                    datatype: 'json',
                    credentials: cred,
                    body: JSON.stringify(accidentHistoryReport),                  
                }
                setLoading(true);
                const response = await fetch(query, requestOptions);
                if (response.status !== 200){
                    if (response.status === 401){
                        onUnauthorized();
                    };
                    throw new Error('Error while executing request.');
                };          
                const json = await response.json();
                onSuccess(json);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                throw new Error('Error while executing the request.');
            }
        };
        fetchData();
    
    }

    return [ onSubmit, data, loading ] as const;
};