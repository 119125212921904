import React from "react";
import styled from "styled-components";

import { BrowserRouter as Router } from "react-router-dom";
import { StyledText, withCustomStyles } from "@shift/gears-design-system";

import { Providers } from "./Providers";
import Routing from "./Routing";

const ApplicationContainer = styled(withCustomStyles(StyledText))`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

ApplicationContainer.displayName = "ApplicationContainer"

export const ApplicationWrapper = () => (
  <React.StrictMode>
    <Router>
      <Providers>
        <ApplicationContainer type="div">
          <Routing />
        </ApplicationContainer>
      </Providers>
    </Router>
  </React.StrictMode>
);
