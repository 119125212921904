import { BaseRadioTheme, DeepPartial, RadioStates, RadiosTheme, ThemeContext, applyStyle } from '@shift/gears-design-system';
import { colors } from 'constants/colors';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
export interface RadioInputProps {
    children: React.ReactNode;
    value: string;
    error?: boolean;
    name?: string;
    id?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange: (value: boolean) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

const InnerRadioInput = styled.input`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 100%;
`;

const applyRadio = (theme?: DeepPartial<BaseRadioTheme>) => css`
    &:before {
        content: '';
        ${applyStyle('border-radius', theme?.borderRadius)};
        ${applyStyle('border', theme?.border)};
        ${applyStyle('color', theme?.color)};
        ${applyStyle('width', theme?.width)};
        ${applyStyle('height', theme?.height)};
        ${applyStyle('margin', theme?.margin)};
        ${applyStyle('line-height', theme?.lineHeight)};
        ${applyStyle('background-color', theme?.backgroundColor)};
        ${applyStyle('border-color', colors.border?.default)};
        display: flex;
        transition: all 250ms ease;
        box-shadow: inset 0 0 0 1.5px white;
   }
  }
`;

const InnerRadio = styled.span<{ radiosTheme: RadiosTheme; state: RadioStates }>`
    ${({ radiosTheme, state }) => css`
        ${applyRadio(radiosTheme[state].$normal)}
        &:hover {
            ${applyRadio(radiosTheme[state].$hover)}
        }
    `}
`;

const RadioContainer = styled.label<{ radiosTheme: RadiosTheme; state: RadioStates }>`
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: normal;
    align-items: center;
    cursor: pointer;
    ${({ radiosTheme, state }) => css`
        input:checked ~ ${InnerRadio} {
            ${applyRadio(radiosTheme[state].$checked)}
        }
    `}
`;

export const CustomRadioInput = ({
    value,
    onChange,
    onFocus,
    onBlur,
    error,
    children,
    name,
    id,
    disabled,
    checked = false,
}: RadioInputProps) => {
    const { radios } = useContext(ThemeContext);

    // eslint-disable-next-line no-nested-ternary
    const state: RadioStates = disabled ? 'disabled' : error ? 'error' : 'default';

    const doChange = () => onChange(!checked);

    return (
        <RadioContainer radiosTheme={radios} state={state}>
            <InnerRadioInput
                onChange={doChange}
                type="radio"
                key={name}
                id={id}
                value={value}
                name={name}
                disabled={disabled}
                defaultChecked={checked}
                onFocus={onFocus}
                onBlur={onBlur}
         />
            <InnerRadio radiosTheme={radios} state={state} />
            {children}      
        </RadioContainer>
    );
};
