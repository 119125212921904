import React, { useContext, useEffect } from 'react';
import { CircularLoader, ThemeContext } from '@shift/gears-design-system';
import config from 'configuration/config';
import { routes } from 'entrypoint/Routing';
import { useHistory } from 'react-router-dom';
import { mapMyInfoToReportState } from 'utils/mapping/mapMyInfoToReportState';
import { useQueryStringParams } from 'hooks';
import { useAppState } from 'state';

enum MyinfoErrors {
    AccessDenied = "access_denied"
}

export const CallbackAccidentHistoryReport = () => {
    let query = useQueryStringParams();
    const { updateReportState } = useAppState();
    const history = useHistory();
    const { colors } = useContext(ThemeContext);

    const myinfoAuthCode: string = query.get("code") + "";
    const myinfoAuthState: string = query.get("state") + "";
    const myinfoError = query.get("error");
    const requestData = {
        "authCode": myinfoAuthCode,
        "state": myinfoAuthState,
        "simplifiedMyinfoRequest": true,
        "mobileReporting": true,
    };

    var personDataUrl = config.myInfo.accidentHistoryReportPersonUrl + "";
    var introductionPageUrl = routes.accidentHistoryReport;
    var callbackUrl = routes.accidentHistoryReportCallback;
    var contactInfo = routes.accidentHistoryReportContactInfo;
    var cred: RequestCredentials = "include";
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: cred,
        body: JSON.stringify(requestData)
    }

    useEffect(() => {
        if (myinfoError === MyinfoErrors.AccessDenied) {
            history.replace(introductionPageUrl, { referrer: callbackUrl });
            return;
        }

        const fetchData = async () => {
            try {
                const response = await fetch(personDataUrl, requestOptions);
                const json = await response.json();
                if (!response.ok) {
                    updateReportState({ authenticationInfo: { isAuthenticated: false } });
                    throw new Error("Failed to retrieve personal data, contact support.", { cause: json });
                }
                updateReportState(mapMyInfoToReportState(json));
                updateReportState({ authenticationInfo: { isAuthenticated: true } });
                history.replace(contactInfo);
            } catch (error: any) {
                history.replace(introductionPageUrl, {
                    error: `Failed to retrieve personal data, contact support. ${error?.cause?.traceId ? " Support trace number: " + error.cause.traceId : ""}`
                });
            }
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div style={{ margin: "auto" }}>
                <CircularLoader color={colors.brand.primary.base} width='16px' size='100px' />
            </div>
        </>
    );
};
