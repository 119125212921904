import React, { useState } from "react";
import { PaddedInputForm, ReportPageWrapper } from "components";
import { useAppState } from "state";
import { DrivingLicensePanel, IDrivingLicenseProps, IKeyValuePairBoldedProps, SimplePanel } from "./commonComponents";
import { Textarea } from "@shift/gears-design-system";
import { routes } from "entrypoint/Routing";
import { PanelWithEdit } from "./commonComponents";
import { dateUtils } from "utils/dates";

export const ReviewMyInfoDetails = () => {
  const {
    reportState: { personalInfo, regAdd, licenceInfo, vehicleInfo, isVehicleOwner, comment: savedComment, isVehicleDriver },
    updateReportState,
  } = useAppState();

  const [comment, setComment] = useState<string>(savedComment ?? "");

  const driverSection: IKeyValuePairBoldedProps[] = [
    { label: "Name", value: personalInfo?.name },
    { label: "Gender", value: personalInfo?.sex },
    { label: "ID (NRIC/FIN)", value: personalInfo?.ufin },
    { label: "Date of birth", value: dateUtils.toSingaporeDateFormat(personalInfo?.dob) },
    { label: "Address", value: `BLK ${regAdd?.block} ${regAdd?.street}` },
    { label: "", value: `${regAdd?.floor}-${regAdd?.unit} ${regAdd?.building}` },
    { label: "", value: `${regAdd?.country} ${regAdd?.postal}` },
  ];

  const licenceSection: IDrivingLicenseProps = {
    validity: [{label:"Validity",value: licenceInfo?.validityDesc}],
    classes: licenceInfo?.classes
   
  };

  const vehicleSection: IKeyValuePairBoldedProps[] = [
    { label: "Registration number", value: vehicleInfo?.vehicleno },
    { label: "Make", value: vehicleInfo?.make },
    { label: "Type", value: vehicleInfo?.type },
    { label: "Model", value: vehicleInfo?.model },
    { label: "Engine capacity (CC)", value: vehicleInfo?.enginecapacity?.toString() },
    { label: "Effective Date/Time of Ownership", value: dateUtils.toSingaporeDateTimeFormat(vehicleInfo?.effectiveownershipdate) },
    { label: "Propellant", value: vehicleInfo?.propellant },
    { label: "Chassis no", value: vehicleInfo?.chassisno },
    { label: "First registration date", value: dateUtils.toSingaporeDateFormat(vehicleInfo?.firstregistrationdate) },
  ];

  return (
    <ReportPageWrapper
      stepNumber={6}
      pageTitle="Review the information collected through Myinfo Singpass"
      onSubmitFn={() => updateReportState({ comment: comment ? comment : undefined })}
      submitButtonText="Check and confirm"
      nextRoute="reviewReportDetails"
    >
      <SimplePanel data={driverSection} title={isVehicleDriver ? "Driver" : "Owner"} />
      {isVehicleDriver && <DrivingLicensePanel data={licenceSection} title="Qualified driving licence" />}
      {isVehicleOwner && <PanelWithEdit data={vehicleSection} editRoute={routes.vehicleInfo} title="Vehicle" />}
      <PaddedInputForm label="Additional comments">
        <Textarea value={comment} onChange={setComment}></Textarea>
      </PaddedInputForm>
    </ReportPageWrapper>
  );
};
