
import { ExclamationTriangle } from "@shift/gears-icons";
import { colors } from "constants/colors";
import { IClaimSearchResult } from "hooks";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { dateUtils } from "utils/dates";
import { PageText } from "./PageText";

const StyledExclamationIcon = styled(ExclamationTriangle)`
  font-size: 1em;
  color: #d01010;
`;

const BoldDiv = styled.div`
  font-weight: bolder;
`;

interface IClaimResultCardProps {
  claim: IClaimSearchResult;
}

const hoursLimit = 24;

export const ClaimResultCard = (props: IClaimResultCardProps) => {
  const { referenceCode, submissionDate: submissionDateUtc, vehicleNo } = props.claim;

  const submissionDate = dateUtils.fromShiftDateTime(submissionDateUtc);
  const currentDate = dateUtils.getCurrentUtcDateTime();
  const hoursDifference = moment.duration(currentDate.diff(submissionDate)).asHours();
  const isEndDateExceeded = hoursDifference >= hoursLimit;

  const initiatedOnElement = (
    <>
      <div>Vehicle number: {vehicleNo}</div>
      <div>
        Initiated on {dateUtils.toSingaporeDateFormat(submissionDate)} at {dateUtils.toSingaporeTimeFormat(submissionDate)}
      </div>
    </>
  );

  const referenceCodeElement = <span style={{ color: colors.text?.information }}>{referenceCode}</span>;

  return (
    <PageText>
      {isEndDateExceeded ? (
        <>
          <BoldDiv>
            <StyledExclamationIcon withDefault={false} />
            <span style={{ color: "#D01010", marginLeft: "5px" }}>Accident Report Number </span>
            {referenceCodeElement}
          </BoldDiv>
          {initiatedOnElement}
          <div style={{ color: "#D01010" }}>You've missed the deadline!</div>
          <div>Go to an Authorised Reporting Centre (ARC) as soon as possible!</div>
        </>
      ) : (
        <>
          <BoldDiv>
            <span>Accident Report Number </span>
            {referenceCodeElement}
          </BoldDiv>
          {initiatedOnElement}
          <div>Go to an Authorised Reporting Centre (ARC) before the end of the next business day.</div>
        </>
      )}
    </PageText>
  );
};
