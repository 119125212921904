import { DateFormat, DateTimeDisplayFormat, DateOffsetFormat, DefaultTimezone, InputDateOffsetFormat, ShiftDateTimeFormat, TimeDisplayFormat, DateUtcFormat } from "constants/dates";
import moment from "moment";

type DateInput = string | Date | undefined;

const getCurrentSingaporeDateTime = () => moment().tz(DefaultTimezone);

const getCurrentUtcDateTime = () => moment().utc();

const toSingaporeDateTime = (date: DateInput) =>
  date ? moment(date).tz(DefaultTimezone) : undefined;

const toSingaporeDateFormat = (date: DateInput) =>
  date && moment(date).isValid() ? moment(date).tz(DefaultTimezone).format(DateFormat) : undefined;

const toSingaporeTimeFormat = (date: DateInput) =>
  date ? moment(date).tz(DefaultTimezone).format(TimeDisplayFormat) : undefined;

const toSingaporeDateTimeFormat = (date: DateInput) =>
  date && moment(date).isValid() ? moment(date).tz(DefaultTimezone).format(DateTimeDisplayFormat) : undefined;

export const toShiftDateTimeOffsetFormat = (date: DateInput) => 
  (date && moment(date).isValid() ? moment(date).tz(DefaultTimezone).format(DateOffsetFormat) : undefined);

const fromShiftDateTime = (date: string | undefined) =>
  date ? moment.utc(date, ShiftDateTimeFormat, true).toDate() : undefined;

export const toUtcDateTimeFormat = (date: string | undefined) => 
  (date && moment(date).isValid() ? moment.utc(date, InputDateOffsetFormat, false) : undefined);

export const toUtcDateFormat = (date: string | undefined) => 
  (date && moment(date).isValid() ? moment(date).tz(DefaultTimezone).format(DateUtcFormat) : undefined);

const parseOffsetDate = (value: string, format: string = InputDateOffsetFormat) => moment(value, format, true).tz(DefaultTimezone);


export const dateUtils = {
  getCurrentSingaporeDateTime,
  getCurrentUtcDateTime,
  toSingaporeDateTime,
  toSingaporeDateFormat,
  toSingaporeTimeFormat,
  toSingaporeDateTimeFormat,
  fromShiftDateTime, 
  parseOffsetDate,
  toShiftDateTimeOffsetFormat, 
  toUtcDateTimeFormat,
  toUtcDateFormat
}