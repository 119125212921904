import React from "react";
import { Grid, GridItem } from "@shift/gears-design-system";
import { PageHeader } from "components/PageHeader";
import { ContentWrapper } from "styles/Style.Media";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { SecondaryTextButton } from "components";
import { routes } from "entrypoint/Routing";
const PageContent = styled.div`
    text-align:center;
    margin:15px;
`;

export const MyinfoErrorPage = (id: any) => {
    const history = useHistory();
   
    return (
        <>
            <PageHeader />
            <ContentWrapper>
                <Grid direction="column" style={{ minHeight: "100%" }}>
                    <GridItem flex={100}>
                        <PageContent>
                            Service unavailable.
                            We are currently unable to process your request.
                            Please try later.
                        </PageContent>
                    </GridItem>
                    <GridItem flex={1} style={{ display: "inline-grid" }}>
                        {<SecondaryTextButton onClick={() => { history.replace(routes.accidentHistoryReport) }}>Go to home page</SecondaryTextButton>}
                        <br />
                    </GridItem>
                </Grid>
            </ContentWrapper>
        </>
    );
};
