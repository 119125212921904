import { routes } from "entrypoint/Routing";
import { useHistory } from "react-router-dom";
import { queryStringParams, useQueryStringParams } from "./useQueryStringParams";

export const useNextRoute = (defaultRoute: keyof typeof routes) => {
  const history = useHistory();
  const queryParam = useQueryStringParams();
  const fromReview = queryParam.get(queryStringParams.fromReview);

  if (fromReview){
    const nextRoute = undefined;
    const goToNextRoute = () => history.goBack();
    return { nextRoute, goToNextRoute }
  }

  const nextRoute = routes[defaultRoute];
  const goToNextRoute = () => history.push(nextRoute);

  return { nextRoute, goToNextRoute };
};
