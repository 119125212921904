import { gql } from "@apollo/client";
import { useMemo } from "react";
import { useQueryWithErrorHandling } from "./errorHandlers";

export interface IEnumModel {
  enumName: string;
  value: string;
  valueWithEnumName: string;
}

export const useEnum = (name: string) => {
  const { data, loading, refetch, error } = useQueryWithErrorHandling(ENUMS);

  const enumValues: IEnumModel[] = useMemo(() => mapEnumValues(name, data), [name, data]);

  return { loading, enumValues, refetch, error };
};

const mapEnumValues = (name: string, data: any) => {
  return data?.enums?.[name]?.map((x: any) => ({ value: x.Value, enumName: x.Name, valueWithEnumName: fullName(x) } as IEnumModel)) ?? [];
};

const fullName = (data: any) => `${data.Name}.${data.Value}`;

const ENUMS = gql(`
    query Enums {
        enums
    }
`);
