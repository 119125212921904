import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import config from 'configuration/config';
import { routes } from 'entrypoint/Routing';
import { IntroductionReporting } from './IntroductionReporting';

export const Home = () => {
    const { authState, oktaAuth } = useOktaAuth();

    const loginWithSingpass = async () => {
        await oktaAuth.token.getWithRedirect(config.tokenParam);
    };

    return (
        <>
            {!authState?.isAuthenticated ?
                <IntroductionReporting action={loginWithSingpass} /> : <Redirect to={routes.checkUser} />}
        </>
    );
};