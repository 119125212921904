import React, { useContext, useEffect, useState } from "react";
import { CircularLoader, ThemeContext } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { PageTitle } from "components/PageTitle";
import { PageText } from "components/PageText";
import config from "configuration/config";
import logo from "resources/myinfo_retrival.png";
import { ImageButton } from "components";
import { AlertDialog } from "components/AlertModal";
import { routes } from "entrypoint/Routing";
import { useErrorMessageContext } from "components/ErrorMessageProvider";
import { useAppState } from "state";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { GearsVehicleAccidentReportHistoryTitle } from "../../constants";
import { useGetQuery } from "utils/fetch/FetchProvider";
import { BillableItem, mapBillableItem } from "types/IBillableItemPrice";
import styled from "styled-components";
import image from "resources/gia_logo.png";
import { useAuth } from "hooks/useAuth";

const OrderedList = styled.ol`
    counter-reset: item;
    list-style-position: outside;
    padding-left:0;
    margin-top:0;
    li{
        display: block;
        margin-left: 1.7em;
        &:before{
            content: counter(item) ". ";
            counter-increment: item;
            position: absolute;
            margin-left: -1.7em;      
        }
        p{
            margin:0;
        }
    }
`;

const ButtonContainer = styled(PageText)`
    display: flex;
    justify-content: center;
`;

const authState = Math.floor(Math.random() * 100000);

const authoriseUrl =
    config.myInfo.authUrl +
    "?client_id=" +
    config.myInfo.accidentHistoryReportClientId +
    "&attributes=" +
    config.myInfo.accidentHistoryReportAttributes +
    "&purpose=" +
    config.myInfo.purpose +
    "&state=" +
    encodeURIComponent(authState) +
    "&redirect_uri=" +
    config.myInfo.accidentHistoryReportRedirectUrl;

export const IntroductionAccidentHistoryReport = ({props}: any) => {
    useDocumentTitle(GearsVehicleAccidentReportHistoryTitle);
    const { onUnauthorized } = useAuth();
    const [redirecting, setRedirecting] = useState(false);
    const { colors } = useContext(ThemeContext);
    const [showDialog, setShowDialog] = useState(props?.location?.state?.referrer === routes.accidentHistoryReportCallback);
    const { displayMessage } = useErrorMessageContext();
    const { clearReportState } = useAppState();
    const { data, loading } = useGetQuery<BillableItem>(config.accidentHistoryReportResourceServer.api.vehicleAccidentReportHistoryPrice, mapBillableItem, onUnauthorized);

    useEffect(() => {
        if (props?.location?.state?.error) {
          displayMessage([props.location.state?.error])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    const submit = () => {
        clearReportState();
        setRedirecting(true);
        window.location.href = authoriseUrl;
    };

    if (redirecting)
    return (
      <div style={{ margin: "auto" }}>
        <CircularLoader color={colors.brand.primary.base} width="16px" size="100px" />
      </div>
    );
    return (
        <>
        <ContentWrapper>
        {loading ? (
                <div style={{ margin: "auto" }}>
                    <CircularLoader color={colors.brand.primary.base} width="16px" size="100px" />
                </div>
            ) :(
                <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={image} alt="" style={{ width: "60%", marginBottom: "10px", marginTop: "10px", alignContent: "center" }} />
                </div>
                <PageTitle alignment="center">Vehicle Accident Report History Purchase</PageTitle>
                <br />
                <PageText>
                    Important Notes:
                    <br />
                    <OrderedList>
                        <li><p>This application is only intended for the purchase <span style={{ fontWeight: "bolder" }}>of the reports for the vehicles you
                            own. </span>Only individuals can buy your own Vehicle Accident Report History. The feature
                            for vehicles owned by businesses or entities is not available yet.</p></li>
                        <li><p>The cost per report is S${data?.price ?? 0} plus prevailing GST.</p></li>
                        <li><p>Payment options available are: PayNow and Credit Card.</p></li>
                    </OrderedList>
                </PageText>
                <PageText>
                    We will automatically fill your personal information using Singpass’s Myinfo solution. By pressing the button below you’ll be redirected to Myinfo’s verification page.
                </PageText>
                <PageText>
                    To buy the report, please
                </PageText>
                <ButtonContainer>
                    <ImageButton style={{ width: "85%" }} src={logo} onClick={() => submit()} alt="my info login" />
                </ButtonContainer>
            <PageText>
                <span style={{ fontWeight: "bolder", color: "red" }}>Disclaimer </span>
            </PageText>
            <PageText>
                <span style={{ color: "red" }}>
                    The information contained in the report has been collated from and/or provided to the General
                    Insurance Association of Singapore (GIA) by various third parties. No liability (in tort, contract
                    or otherwise) attaches to GIA with respect to the collation or supplying of the information, the
                    report or any use made of it whether in relation to its accuracy or completeness or any other
                    matter.
                </span>
            </PageText>
            <PageText>
                <b>FAQ:</b> For more information on VARH, please click <a style={{color: "black"}} href="https://gia.org.sg/images/resources/For-Consumers-PDF-file/GEARS-VARH-FAQ.pdf">here</a>.
            </PageText>
                </>
            )}
            </ContentWrapper>
            <AlertDialog title="Information"
                message="Connection to Myinfo is required to request the Vehicle Accident Report History on this site."
                subMessage=""
                button={<ImageButton src={logo} onClick={() => submit()} alt="my info login" />}
                showDialog={showDialog}
                showDialogHandler={setShowDialog}
            />
        </>
    )
};
