import { Button, Grid, GridItem, StyledText } from "@shift/gears-design-system";
import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "constants/colors";
import { useHistory } from "react-router-dom";
import logo from "resources/gears_logo.svg";
import { UserCircleSvg, ChevronLeftSvg } from "@shift/gears-icons";
import { useOktaAuth } from "@okta/okta-react";
import { useErrorMessageContext } from "./ErrorMessageProvider";

export const PageHeader = ({
  alternativeOnBackFn,
  hideBackButton,
}: {
  alternativeOnBackFn?: () => void;
  hideBackButton?: boolean;
}) => {
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const userEmail = authState?.idToken?.claims.email + "";
  const userName = "User: " + userEmail.split("@")[0];
  const { clearMessage } = useErrorMessageContext();

  const logout = async () => oktaAuth.signOut();

  useEffect(() => {
    clearMessage();
  }, [clearMessage]);

  return (
    <StyledGrid>
      <GridItem flex={1}>
        {!hideBackButton && (
          <StyledLink onClick={alternativeOnBackFn ? alternativeOnBackFn : () => history.goBack()}>
            <ChevronLeftSvg withDefault={false} /> Back
          </StyledLink>
        )}
      </GridItem>
      <GridItem flex={1}>
        <Grid justifyPlacement="center">
          <img src={logo} height="70%" alt="GEARS" />
        </Grid>
      </GridItem>
      <GridItem flex={1}>
        <Grid justifyPlacement="end">
          <DropdownContianer>
            <StyledUserIcon withDefault={false} />
            <DropdownContentMenu>
              <StyledText>
                <p>{userName}</p>
              </StyledText>
              <DropdownButton onClick={logout}>Logout</DropdownButton>
            </DropdownContentMenu>
          </DropdownContianer>
        </Grid>
      </GridItem>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
  background-color: ${(_) => colors.brand?.primary?.base};
  padding: 10px;
  color: ${(_) => colors.text?.primary};
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
`;

const StyledUserIcon = styled(UserCircleSvg)`
  font-size: 1.5em;
  color: white;
`;

const DropdownContentMenu = styled.div`
  display: none;
  right: 0;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  background-color: white;
  z-index: 9999;
  height: 100px;
  width: 200px;
  border: 1px solid ${(_) => colors.brand?.primary?.base};
  border-radius: 4px;
  text-align: center;
`;

const DropdownContianer = styled.div`
  position: relative;
  &:hover ${DropdownContentMenu} {
    display: block;
  }
`;

const DropdownButton = styled(Button)`
  width: 80%;
  margin: auto;
  display: block;
`;
