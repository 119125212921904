import React from "react";
import { Button, Grid, GridItem } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { PageTitle } from "components/PageTitle";
import { PageText } from "components/PageText";
import { useAppState } from "state/AppStateProvider";
import { useHistory } from "react-router-dom";
import { CustomInputGroup } from "components/CustomInputGroup";
import { routes } from "entrypoint/Routing";
import { PageHeaderAccidentHistoryReport } from "components/PageHeaderAccidentHistoryReport";


export const VehicleSelectionAccidentHistoryReport = (props: any) => {
    const history = useHistory();
    const {
        reportState: { ownedVehicles, selectedVehicle },
        updateReportState,
    } = useAppState();

    function MapOptions(ownedVehicles: any) {
        let mappedVehicles: { [x: string]: { labelName: string; labelDescription: any; }; } = {};
        ownedVehicles?.forEach((item: { make: any; model: any; vehicleno: any; }, index: string | number) => {
            mappedVehicles = { [index]: { labelName: item.model.toUpperCase().startsWith(item.make.toUpperCase()) ? `${item.model}` : `${item.make} ${item.model}`, labelDescription: item.vehicleno.toUpperCase() }, ...mappedVehicles };
        }
        );
        return mappedVehicles;
    }

    function onChangeSelectedVehicle(e: string) {
        if (ownedVehicles !== undefined) {
            updateReportState({ selectedVehicle: ownedVehicles[parseInt(e)] })
        }

        return e;
    }

    function selectedValue(): string | null {
        if (ownedVehicles && selectedVehicle) {
            let index = ownedVehicles.findIndex((e, i) => e.chassisno === selectedVehicle.chassisno && e.vehicleno === selectedVehicle.vehicleno);
            return `${index}`;
        }
        return null;
    }
    function isVehicleSelected(): boolean | undefined {
       return !(selectedVehicle !== undefined && ((ownedVehicles?.findIndex((e, i) => e.chassisno === selectedVehicle.chassisno && e.vehicleno === selectedVehicle.vehicleno)) !== -1))
    }

    return (
        <>
            <PageHeaderAccidentHistoryReport />
            <ContentWrapper>
                <Grid direction="column" style={{ minHeight: "100%" }}>
                    <GridItem flex={100}>
                        <PageTitle>Vehicle information</PageTitle>

                        {ownedVehicles && ownedVehicles.length > 0 ?
                            <><PageText>
                                Please select a vehicle you want a report from.
                            </PageText>
                                <CustomInputGroup value={selectedValue()} onChange={e => onChangeSelectedVehicle(e)} options={MapOptions(ownedVehicles)}></CustomInputGroup></> :
                            <PageText>No vehicles were found for this user.</PageText>}

                    </GridItem>
                    <GridItem flex={1} style={{ display: "inline-grid" }}>
                        {<Button onClick={() => { history.push(routes.accidentHistoryReportVehicleSelectionConfirmation) }} disabled={isVehicleSelected()}>Continue</Button>}
                        <br />
                    </GridItem>
                </Grid>
            </ContentWrapper>
        </>
    );
};
