import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

export const useCreateUser = () => {
  const [check, { loading, data, error }] = useMutation(CHECK_USER);
  const [repeatFlag, setRepeatFlag] = useState(false);

  useEffect(() => {
    const runCheck = async () => {
      const response = await check();

      if (!response.data.createUser) {
        throw new Error("Unable to create user in GEARS");
      }
    };

    runCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatFlag]);

  const repeat = () => {
    setRepeatFlag((prev) => !prev);
  };

  return { loading, data, error, repeat };
};

const CHECK_USER = gql(`
    mutation {
      createUser
    }
`);
