import { Theme } from '@shift/gears-design-system';
import { colors } from './colors';

export const theme: Theme = {
    navbar: {
        color: colors.background?.white,
    },
    card: {
        minWidth: undefined,
    },
    modal: {
        maxWidth: '50%',
    },
    table: {
        headerCell: {
            default: {
                backgroundColor: colors.background?.white,
            },
        },
    },
    map: {
        default: {
            height: '280px',
        },
    },
};
