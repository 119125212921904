import { IAppState } from "state";

const key = "mobile-ar-state";

export function loadState(initialState: IAppState): IAppState {
  try {
    const serializedState = sessionStorage.getItem(key);

    if (!serializedState) return initialState;

    return JSON.parse(serializedState);
  } catch (e) {
    console.log(`Failed to load state: ${e}`)
    return initialState;
  }
}

export function saveState(state: IAppState) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(key, serializedState);
  } catch (e) {
    console.log(`Failed to save state: ${e}`)
  }
}