import React, { useContext } from "react";
import { Grid, GridItem, IconButton, KeyValuePair, RowType, StyledHeader, Table, ThemeContext } from "@shift/gears-design-system";
import styled from "styled-components";
import { EditSvg } from "@shift/gears-icons";
import { routes } from "entrypoint/Routing";
import { queryStringParams } from "hooks";
import { useHistory } from "react-router-dom";
import { ILicenseClass } from "state/appStateModel";
import { dateUtils } from "utils/dates";
export interface IKeyValuePairBoldedProps {
  label: string;
  value: string | undefined;
}

export interface IDrivingLicenseProps {
  validity: IKeyValuePairBoldedProps[];
  classes: ILicenseClass[] | undefined;
  }

export const Panel = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

export const PanelHeader = styled(StyledHeader).attrs({ type: "h3", weight: "bold" })`
  margin-bottom: 10px;
`;

export const DrivingLicenceTable = styled.table`
  width: 100%;
  table-layout: fixed;
  tr th{
    text-align: left;
    background: $colors.brand.primary.base;
    color: white;
    padding: 8px;
  }
  td{
    background: white;
    border: solid 1px black;
    padding: 8px;
  }
`;

const PanelHeaderWithEdit = ({ children, onClick }: { children: string; onClick: () => void }) => {
  return (
    <Grid>
      <GridItem flex={99}>
        <PanelHeader>{children}</PanelHeader>
      </GridItem>
      <GridItem flex={1}>
        <EditButtonWrapper>
          <IconButton icon={<EditSvg />} onClick={onClick} text={{ size: "small" }} spaceBetween={0}>
            {null}
          </IconButton>
        </EditButtonWrapper>
      </GridItem>
    </Grid>
  );
};

const PanelItem = ({ label, value }: IKeyValuePairBoldedProps) => {
  return (
    <KeyValuePair
      label={<span style={{ fontWeight: "bold", marginRight: "4px" }}>{label}</span>}
      value={value ? <span style={{ marginLeft: "4px", wordBreak: "break-all" }}>{value}</span> : ""}
    />
  );
};

const EditButtonWrapper = styled.div`
  button {
    padding: 0;
    height: 30px;
    width: 35px;
  }
`;

export const SimplePanel = (props: { data: IKeyValuePairBoldedProps[]; title: string }) => {
  return (
    <Panel>
      <PanelHeader>{props.title}</PanelHeader>
      {props.data.map((x, i) => (!x.value ? null : <PanelItem {...x} key={i} />))}
    </Panel>
  );
};

export const PanelWithEdit = (props: {
  data?: IKeyValuePairBoldedProps[];
  editRoute: ValueOf<typeof routes>;
  title: string;
  isImg?: boolean;
}) => {
  const history = useHistory();
  return (
    <Panel>
      <PanelHeaderWithEdit onClick={() => history.push(`${props.editRoute}?${queryStringParams.fromReview}=true`)}>
        {props.title}
      </PanelHeaderWithEdit>
      {props.data?.map((x, i) =>
        !x.value ? null : props.isImg ? (
          <img height="56px" src={x.value} alt={x.label} key={i} style={{ marginRight: "5px" }} />
        ) : (
          <PanelItem {...x} key={i} />
        )
      )}
    </Panel>
  );
};

export const DrivingLicensePanel = (props: { data: IDrivingLicenseProps; title: string }) => {
const { colors } = useContext(ThemeContext);

  var listItems = props.data.classes?.map(function(licenseClass, i) {
    return(
        <tr key={i}>
            <td>{licenseClass['class']}</td>
            <td>{dateUtils.toSingaporeDateFormat(licenseClass['issueDate'])}</td>
        </tr>
    )
  });
  return (
    <Panel>
      <PanelHeader>{props.title}</PanelHeader>
      {props.data.validity.map((x, i) => (!x.value ? null : <PanelItem {...x} key={i} />))}
      {listItems?.length !== undefined && listItems?.length > 0 && <DrivingLicenceTable>
            <tbody id="content">
                <tr>
                    <th style={{backgroundColor:colors.brand.primary.base}}>Class</th>
                    <th style={{backgroundColor:colors.brand.primary.base}}>Issue Date</th> 
                </tr>
                {listItems}
            </tbody>
        </DrivingLicenceTable>}
    </Panel>
  );
};



type ValueOf<T> = T[keyof T];
