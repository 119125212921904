import React from 'react';
import { useAuth } from "hooks/useAuth";
import { Redirect } from 'react-router-dom';
import { routes } from 'entrypoint/Routing';


interface Props {
    children?: JSX.Element
  };

export const RequiredAuthentication = ({children}:Props): JSX.Element => {
    const { authenticated } = useAuth();

    return (!authenticated ? <Redirect to={routes.accidentHistoryReport} /> : <>{children}</>
    );
};