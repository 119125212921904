/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import uniqid from 'uniqid';
import { Grid, GridItem, StyledText, withCustomStyles } from "@shift/gears-design-system";
import { CustomRadioInput } from './CustomRadioInput';
import styled from 'styled-components';
import { VrnCard } from './VrnCard';

export interface RadioOptions {
    [key: string]: {
        labelName: string;
        labelDescription: string;
    };
}

export interface RadioGroupInputProps {
    disabled?: boolean;
    error?: boolean;
    options: RadioOptions;
    value: string | null;
    name?: string;
    onChange: (selected: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const CustomInputGroup = ({
    disabled,
    onChange,
    onFocus,
    onBlur,
    error,
    options,
    value,
    name,
}: RadioGroupInputProps) => {
    const onChangeCb = (key: string, selected: boolean) => {
        if (disabled || !selected || value === key) return;
        onChange(key);
    };


const SelectionGridItem = styled(withCustomStyles(GridItem))`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-bottom: solid 1px grey;
    padding-left: 5px;
`;
    const requiredName = useMemo(() => name || uniqid(), [name]);

    return (
        <Grid
         direction="column"
        >
            {options &&
                Object.keys(options).map((key, index) => {
                    const { labelName, labelDescription } = options[key];
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <SelectionGridItem key={`GridItem-RadioInput-${index}`}>                      
                            <CustomRadioInput
                                onChange={(selected) => onChangeCb(key, selected)}
                                value={key}
                                name={requiredName}
                                id={name ? `${name}-${key}` : key}
                                error={error}
                                checked={value === key}
                                disabled={disabled}
                                key={`RadioInput-${index}`}
                                onFocus={onFocus}
                                onBlur={onBlur}
                            >
                              <VrnCard title={labelName} description={labelDescription}></VrnCard>
                            </CustomRadioInput> 
                        </SelectionGridItem>
                    );
                })}
        </Grid>
    );
};
