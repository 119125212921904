import { vehicleFuels } from "utils/mapping/mapReportStateToGears/constants/vehicleFuels";
import { vehicleMakes } from "./constants/vehicleMakes";
import { vehicleModels } from "./constants/vehicleModels";
import { IReportState, IVehicleInfo } from "state";
import { vehicleTypes } from "./constants/vehicleTypes";
import { toUtcDateFormat, toUtcDateTimeFormat } from "utils/dates";
import { DateUtcFormat } from "constants/dates";

export const mapVehicle = (state: IReportState) => {
  const { isVehicleOwner, vehicleInfo: vi } = state;
  if (!vi || !isVehicleOwner) return {};

  const mapping_Object_Motor_Vehicle: any = {};

  mapFuel(mapping_Object_Motor_Vehicle, vi);

  const make = mapMake(mapping_Object_Motor_Vehicle, vi, false);

  mapModel(mapping_Object_Motor_Vehicle, vi, make);

  mapType(mapping_Object_Motor_Vehicle, vi);

  mapEngineCapacity(mapping_Object_Motor_Vehicle, vi);
  mapEffectiveOwnershipDate(mapping_Object_Motor_Vehicle, vi);

  mapFuel(mapping_Object_Motor_Vehicle, vi);
  mapChassisNo(mapping_Object_Motor_Vehicle, vi);

  mapFirstRegistrationDate(mapping_Object_Motor_Vehicle, vi);

  return mapping_Object_Motor_Vehicle;
};

const mapType = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
  if (!vi.type) return;

  const mappedType = Object.entries(vehicleTypes).find(x => x[0].toLocaleLowerCase() === vi.type?.toLocaleLowerCase())
  if (mappedType) {
    mapping_Object_Motor_Vehicle.type = `VehicleType.${mappedType}`;
  }
}

export const mapMake = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo, varh: boolean) => {
  if (!vi.make) return;

  const lowercaseMake = vi.make?.toLocaleLowerCase();
  const make = Object.entries(vehicleMakes).find((x) => x[1].toLocaleLowerCase() === lowercaseMake)?.[0];

  if (make) {
    mapping_Object_Motor_Vehicle.make = varh? make:`VehicleMake.${make}`;
  } else {
    mapping_Object_Motor_Vehicle.makeNotInEnum = true;
    mapping_Object_Motor_Vehicle.makeDescription = vi.make;
  }

  return make;
};

export const mapModel = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo, make: string | undefined) => {
  if (!vi.model) return;

  if (!make) {
    mapping_Object_Motor_Vehicle.model = `${vi.model}___`;
    return;
  }

  const models = Object.entries(vehicleModels)
    .filter((x) => x[0].startsWith(`${make}_`))
    ?.map((x) => ({
      code: x[0].split("_")[1],
      desc: x[1].toLocaleLowerCase(),
    }));

  const lowercaseModel = vi.model?.toLocaleLowerCase();
  const model = models?.find((x) => x.desc === lowercaseModel)?.code;
  mapping_Object_Motor_Vehicle.model = `${model ?? vi.model}___`;
};

const mapFuel = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
  const vehicleFuel = vehicleFuels[vi.propellant ?? ""];
  if (vehicleFuel) {
    mapping_Object_Motor_Vehicle.vehicleFuel = `VehicleFuel.${vehicleFuel}`;
  } else {
    mapping_Object_Motor_Vehicle.vehicleFuelSourceName = vi.propellant;
  }
};

const mapEngineCapacity = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
  if (!vi.enginecapacity) return;

  mapping_Object_Motor_Vehicle.engineCapacityValue = vi.enginecapacity;
}

const mapEffectiveOwnershipDate = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
  if (!vi.effectiveownershipdate) return;
  var result = toUtcDateTimeFormat(vi.effectiveownershipdate);
  mapping_Object_Motor_Vehicle.purchaseDateUtc = result !== undefined ?`${result?.utc().format(DateUtcFormat)}`: undefined;
}


const mapChassisNo = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
  if (!vi.chassisno) return;
  
  mapping_Object_Motor_Vehicle.vIN = vi.chassisno;
}


const mapFirstRegistrationDate = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
  if (!vi.firstregistrationdate) return;

  const result = toUtcDateFormat(vi.firstregistrationdate);
 
  mapping_Object_Motor_Vehicle.firstRegistrationDateUtc = result;
}

export const mapMakeAHR = (vi: IVehicleInfo) => {
  let vehicle: any = {};
  mapMake(vehicle, vi, true);
  let vehicleMakeInfo: any = {};
  if (!vehicle.makeNotInEnum)
  {
    vehicleMakeInfo.make = vi.make;
  }
  vehicleMakeInfo.makeDescription = vehicle.makeDescription;
  vehicleMakeInfo.makeNotInEnum = vehicle.makeNotInEnum;
  return vehicleMakeInfo;
}

export const mapModelAHR = (vi: IVehicleInfo) => {
  let vehicle: any = {};
  let make = mapMake(vehicle, vi, true)
  mapModel(vehicle, vi, make);
  return vehicle?.model;
}

export const mapToUtcDate = (date: string | undefined) => {
  if (!date) return "";

  const [year, month, day] = date.split("-");

  return `${year ?? "1980"}-${month ?? "01"}-${day ?? "01"}\T00:00:00`;
};
