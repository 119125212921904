import { ApolloCache, DefaultContext, MutationHookOptions, MutationTuple, OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import { useErrorMessageContext } from "components/ErrorMessageProvider";
import { DocumentNode } from "graphql";
import { useMemo } from "react";
import { formatError } from "utils/formatters";

export const useMutationWithErrorHandling = <TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>>(mutation: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: MutationHookOptions<TData, TVariables, TContext>): MutationTuple<TData, TVariables, TContext, TCache> => {
  const result = useMutation<TData, TVariables, TContext, TCache>(mutation, options);

  const [, { error }] = result;
  const { displayMessage } = useErrorMessageContext()
  const errors = useMemo(() => formatError(error), [error]);
  if (errors) displayMessage(errors);

  return result;
};


export const useQueryWithErrorHandling = <TData = any, TVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: QueryHookOptions<TData, TVariables>): QueryResult<TData, TVariables> => {
  const result = useQuery<TData, TVariables>(query, options);
  
  const { displayMessage } = useErrorMessageContext()
  const errors = useMemo(() => formatError(result.error), [result.error]);
  if (errors) displayMessage(errors);

  return result;
}