import React from "react";
import { StyledHeader } from "@shift/gears-design-system";
import { colors } from "constants/colors";
import styled from "styled-components";

const Content = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PageSectionTitle = ({ children }: { children: string }) => {
  return (
    <StyledHeader type="h3" weight="semiBold" color={colors.text?.header}>
      <Content>{children}</Content>
    </StyledHeader>
  );
};
