import React from "react";
import { StyledText, withCustomStyles } from "@shift/gears-design-system";
import styled from "styled-components";


const VrnContent = styled.span`
   padding-top: 10px;
`;
const StyledRadioOption = styled(withCustomStyles(StyledText))`
    height: 100%;
    width: 90%;
    font-size: 16px;
    margin-bottom: 5px;
`;

const StyledRadioOptionDescription = styled(withCustomStyles(StyledText))`
    height: 100%;
    width: 90%;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
`;

export const VrnCard = ({ title, description}: { title: string, description: string}) => {
    return (
        <VrnContent>
            <StyledRadioOption>{title}</StyledRadioOption>
            <StyledRadioOptionDescription>{description}</StyledRadioOptionDescription>
        </VrnContent>
    );
};