import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQueryWithErrorHandling } from "./errorHandlers";

interface IInsurers {
  insurers: {
    Type: string;
    Name: string;
  }[]
}

export const useInsurerList = () => {
  const { data, loading, refetch, error } = useQueryWithErrorHandling<IInsurers>(INSURERS);

  const options = useMemo(() => mapInsurerDescription(data), [data]);

  return { loading, options, refetch, error };
};

const mapInsurerDescription = (data: IInsurers | undefined) => {
  return data?.insurers.map((item) => ({
    value: `${item.Type}.${item.Name}`,
    label: InsurerCodeDescription[item.Name] ?? item.Name, //translate(`enums:${item.insurerCode.Type}.${item.insurerCode.Name}`, { defaultValue: item.insurerCode.Name }),
  }));
};

const INSURERS = gql`
  query {
    insurers
  }
`;

// todo: below is copy from project Gears.ARUI.Translations
// as final solution we should reuse same project and "translate" function (above) and remove this copy
// accreditation

const InsurerCodeDescription: Record<string, string> = {
  Aig: "AIG Asia Pacific Insurance Pte. Ltd.",
  Allianz: "Allianz Insurance Singapore Pte. Ltd.",
  Axa: "HSBC Life (Singapore) Pte. Ltd",
  Chubb: "Chubb Insurance Singapore Limited",
  ChinaTaiping: "China Taiping Insurance (Singapore) Pte. Ltd.",
  FirstCapital: "MS First Capital Insurance Ltd",
  IndiaInternational: "India International Insurance Pte Ltd",
  LibertyInsurance: "Liberty Insurance Pte Ltd",
  Etiqa: "Etiqa Insurance Pte Ltd",
  Msig: "MSIG Insurance (Singapore) Pte. Ltd.",
  Ntuc: "Income Insurance Limited",
  GreatEastern: "Great Eastern General Insurance Limited",
  Qbe: "QBE Insurance (Singapore) Pte Ltd",
  AlliedWorld: "Allied World Assurance Company, Ltd",
  Ergo: "ERGO Insurance Pte. Ltd.",
  Sompo: "Sompo Insurance Singapore Pte. Ltd.",
  TokioMarine: "Tokio Marine Insurance Singapore Ltd",
  Uoi: "United Overseas Insurance Ltd",
  Lonpac: "Lonpac Insurance Bhd",
  Eq: "EQ Insurance Company Ltd",
  Aviva: "Singapore Life Ltd",
  ZurichInsurance: "Zurich Insurance Company Ltd (Singapore Branch)",
  DirectAsia: "Direct Asia Insurance (Singapore) Pte Ltd",
  HongLeong: "HL Assurance Pte Ltd",
  Ecics: "ECICS Limited",
  GreatAmericanInsurance: "Great American Insurance Company",
  Fwd: "FWD Singapore Pte. Ltd.",
  Agi: "Auto & General Insurance (Singapore) Pte. Limited.",
  InsurerA: "Insurer A",
  InsurerB: "Insurer B",
  InsurerC: "Insurer C",
  SHIFT: "Shift",
  FederalInsurance: "Federal Insurance Company",
  Gia: "GIA",
};
