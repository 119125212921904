import React, { useContext } from "react";
import { CircularLoader, Grid, GridItem, StyledText, SubmitButton, ThemeContext, withCustomStyles } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { PageTitle } from "components/PageTitle";
import { PageText } from "components/PageText";
import { useAppState } from "state/AppStateProvider";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { VrnCard } from "components/VrnCard";
import { IKeyValuePairBoldedProps, Panel, PanelHeader, SimplePanel } from "pages/reporting/ReviewDetails/commonComponents";
import { BillableItem, mapBillableItem } from "types/IBillableItemPrice";
import { useGetQuery, usePostQuery } from "utils/fetch";
import config from "configuration/config";
import { PageHeaderAccidentHistoryReport } from "components/PageHeaderAccidentHistoryReport";
import { useAuth } from "hooks/useAuth";
import { dateUtils } from "utils/dates";


const StyledPaymentButton = styled(SubmitButton)`
    background-color: #6772E5;
    border-color: #6772E5;
    color:#FFFFFF;
    &:hover,
    &:focus,
    &:active{
        background-color: #6772E5;
        filter: opacity(0.9);
    }
    border-radius: 30px;
`;

const StyledTotal = styled(withCustomStyles(StyledText))`
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
`;

export const VehicleSelectionConfirmationAccidentHistoryReport = (props: any) => {
    const { reportState, updateReportState } = useAppState();
    const { onUnauthorized } = useAuth();
    const billableItemPrice = useGetQuery<BillableItem>(config.accidentHistoryReportResourceServer.api.vehicleAccidentReportHistoryPrice, mapBillableItem, onUnauthorized);
    const onSuccess = (response: any) => {
        let requestID = response;
        let stripeUrl = `${config.stripePurchaseUrl}?client_reference_id=${requestID}`;
        updateReportState({ purchaseId: requestID});
        window.location.href = stripeUrl;
    };
    const [onSubmit, data, loading] = usePostQuery(config.accidentHistoryReportResourceServer.api.vehicleAccidentReportHistoryCreate, reportState, onUnauthorized, onSuccess);
    const history = useHistory();
    const {
        reportState: { selectedVehicle, personalInfo, contactInfo },
    } = useAppState();
    const { colors } = useContext(ThemeContext);

    const vehicleSection: IKeyValuePairBoldedProps[] = [
        { label: "First registration date", value: dateUtils.toSingaporeDateFormat(selectedVehicle?.firstregistrationdate) },
        { label: "Chassis number", value: selectedVehicle?.chassisno },

    ];

    const userSection: IKeyValuePairBoldedProps[] = [
        { label: "NRIC", value: personalInfo?.ufin },
        { label: "Name", value: personalInfo?.name },
    ];

    const contactSection: IKeyValuePairBoldedProps[] = [
        { label: "Email", value: contactInfo?.email },
    ];

    return (
        <>
            <PageHeaderAccidentHistoryReport />
            <ContentWrapper>
                {billableItemPrice.loading ? (
                    <div style={{ margin: "auto" }}>
                        <CircularLoader color={colors.brand.primary.base} width="16px" size="100px" />
                    </div>
                ) :
                    (<>
                        <Grid direction="column" style={{ minHeight: "100%" }}>
                            <GridItem flex={1}>
                                <PageTitle>Review your selection</PageTitle>
                                <PageText>
                                    The report generated will be based on the vehicle you’ve selected as shown below.
                                </PageText>
                                <Panel style={{ paddingLeft: "15px" }}>
                                    <PanelHeader>Vehicle</PanelHeader>
                                    <VrnCard title={selectedVehicle?.model?.toUpperCase().startsWith(selectedVehicle?.make?.toUpperCase() ?? "") ? `${selectedVehicle.model}` : `${selectedVehicle?.make} ${selectedVehicle?.model}`} description={selectedVehicle?.vehicleno?.toUpperCase() ?? ""}></VrnCard>
                                </Panel>
                            </GridItem>
                            <GridItem flex={1}>
                                <SimplePanel data={vehicleSection} title="Other vehicle details" />
                            </GridItem>
                            <GridItem flex={1}>
                                <SimplePanel data={userSection} title="User information" />
                            </GridItem>
                            <GridItem flex={1}>
                                <SimplePanel data={contactSection} title="Contact information" />
                            </GridItem>
                            <GridItem alignPlacement={"end"} flex={100}>
                                <StyledTotal>Total</StyledTotal>
                                <StyledTotal>SGD${billableItemPrice.data?.gstInclusivePrice} inclusive of {billableItemPrice.data?.gst ? billableItemPrice.data?.gst * 100 : 0}% GST</StyledTotal>
                            </GridItem>
                            <GridItem flex={1} style={{ display: "inline-grid" }}>
                                {<StyledPaymentButton submitting={loading} disabled={loading} onClick={onSubmit}>Purchase your report on Stripe</StyledPaymentButton>}
                                <br />
                            </GridItem>
                        </Grid>
                    </>)}
            </ContentWrapper >
        </>
    );
};
