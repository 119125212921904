import React from "react";
import { DocumentCategory } from "types";
import { downscaleImage } from "../../../utils/documents/downscaleImage";
import { uploadFile } from "../../../utils/documents/uploadFile";
import { IFileInfoExtended } from "./PhotoUpload";

export const photoUploadHandler = (
  fileId: number,
  file: File,
  maxFileSize: number,
  setState: React.Dispatch<React.SetStateAction<IFileInfoExtended[]>>,
  token: () => Promise<string | null>,
  documentCategory?: DocumentCategory
) => {
  const updatePhoto = (id: number, newProps: Partial<IFileInfoExtended>) => {
    setState((prev) => {
      const photo = prev.find((x) => x.id === id);
      if (!photo) return [...prev];

      const index = prev.findIndex((x) => x.id === id);
      prev[index] = { ...photo, ...newProps };

      return [...prev];
    });
  };

  const blobUrl = window.URL.createObjectURL(file);

  downscaleImage(blobUrl, file.type, 512, 0.7, (image) => {
    const newPhoto: IFileInfoExtended = {
      id: fileId,
      name: file.name,
      size: file.size,
      progress: 1,
      src: image ?? "no_image",
      thumbnailSrc: image,
    };

    if (file.size / 1024 ** 2 > maxFileSize) {
      newPhoto.errorMessage = `File exceeds ${maxFileSize}MB`;
      newPhoto.progress = undefined;
      setState((prev) => [...prev, newPhoto]);
      return;
    }

    setState((prev) => [...prev, newPhoto]);

    let prevProgress = 0;
    const progressStep = 10;

    updatePhoto(fileId, {
      abortUpload: uploadFile(
        file,
        (progress) => {
          if (progress === 100 || progress > prevProgress + progressStep) {
            prevProgress += progressStep;
            updatePhoto(fileId, { progress });
          }
        },
        (idString: string) => updatePhoto(fileId, { progress: undefined, id: parseInt(idString, 10), abortUpload: undefined }),
        (errorMessage: string) => updatePhoto(fileId, { progress: undefined, errorMessage, abortUpload: undefined }),
        documentCategory,
        token
      ),
    });
  });
};
