export const apiEndpoint = {
  resource: 'graphql',
  documentUpload: 'document/upload',
  pdf: 'document/AccidentReportPdf',
  document: 'document/Id',
  documentPreview: 'document/Preview',
  documentZip: 'document/RecoveryAttachments',
  importedDocument: 'document/ImportedDocument',
  letterOfDemand: 'document/LetterOfDemandPdf',
  reporting: 'Reporting/encrypted-claims',
  vehicleAccidentReportHistoryPrice: 'billableItem/VehicleAccidentReportHistoryPrice',
};

export const accidentHistoryReportApiEndpoint = {
  vehicleAccidentReportHistoryPrice: 'accidentHistoryReport/GetReportPrice',
  vehicleAccidentReportHistory: 'accidentHistoryReport/VehicleAccidentReportHistoryPdf',
  vehicleAccidentReportStatus: 'accidentHistoryReport/GetStatus',
  vehicleAccidentReportCreateReport: 'accidentHistoryReport/Create',
  logout: 'authentication/Logout',
};