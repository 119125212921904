import moment from 'moment-timezone';

export const DefaultTimezone = 'Asia/Singapore';
export const DateFormat = 'DD/MM/YYYY';
export const InputDateFormat = 'YYYY-MM-DD';
export const DateTimeFormat = `${DateFormat} HH:mm:ss`;
export const ShiftDateTimeFormat = `${DateFormat}THH:mm:ss`;
export const DateUtcFormat = `${DateFormat}THH:mm:ss`;
export const DateOffsetFormat = `${DateFormat}THH:mm:ssZ`;
export const InputDateOffsetFormat = `${InputDateFormat}THH:mm:ssZ`;
export const DateTimeDisplayFormat = `${DateFormat} HH:mm`;
export const TimeDisplayFormat = `HH:mm`;
export const DateTimeFileNameFormat = 'DD-MM-YYYY_HHmmss';
export const DateFormatRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
export const now = moment();
export const DefaultTZNow = moment().tz(DefaultTimezone);
export const nowUtc = moment().utc();
export const DefaultTZUtcNow = moment().utc().tz(DefaultTimezone);
