import React, { useCallback, useContext, useState } from "react";
import { loadState, saveState } from "utils/persistance";
import { IAppState, IReportState } from "./appStateModel";

const appInitialState: IAppState = {
  reportState: {},
  updateReportState: () => null,
  clearReportState: () => null
};

const AppStateContext = React.createContext<IAppState>(appInitialState);

const loadedState = loadState(appInitialState);

export const AppStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [appState, setAppState] = useState<IAppState>(loadedState);

  appState.updateReportState = useCallback((payload: IReportState) => {
    setAppState((prev) => {
      const newState = { ...prev, reportState: { ...prev.reportState, ...payload } };
      saveState(newState);
      return newState
    });
  }, []);

  appState.clearReportState = useCallback(() => {
    setAppState((prev) => {
      const newState = { ...prev, reportState: {} };
      saveState(newState);
      return newState
    });
  }, []);

  return <AppStateContext.Provider value={appState}>{children}</AppStateContext.Provider>;
};

export const useAppState = () => useContext(AppStateContext);