import { gql } from "@apollo/client";
import { useQueryWithErrorHandling } from "./errorHandlers";

export interface IClaimSearchResult {
  id: number;
  referenceCode: string;
  status: {
    Type: string;
    Name: string;
  };
  submissionDate: string;
  vehicleNo: string;
}

export const useClaimSearchResult = () => {
  var claimSearchInfo = {
    parameters: {
      start: 0,
      length: 10,
      sort: {
        field: {
          id: "ClaimFields.SubmissionDate",
        },
        order: {
          name: "",
          order: "Order.Desc",
        },
      },
    },
  };

  const { loading, data, refetch, error } = useQueryWithErrorHandling(SubmittedReport, {
    variables: claimSearchInfo,
    skip: !claimSearchInfo,
  });

  const matchedReport: IClaimSearchResult[] = data?.claimList?.items ?? [];

  return { loading, matchedReport, refetch, error };
};

const SubmittedReport = gql`
  query searchClaim($parameters: ClaimFieldsGraphQLSearchParametersInput) {
    claimList(parameters: $parameters) {
      itemsCount
      totalCount
      items {
        id
        referenceCode
        status
        submissionDate
        vehicleNo
        __typename
      }
      __typename
    }
  }
`;
