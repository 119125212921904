import { FileFormats } from "@shift/gears-design-system";
import { IMAGE_FORMATS, VIDEO_FORMATS } from '../../constants';
import { DocumentCategory } from "types";

export type AttachmentType = 'Image' | 'Video';


export const getAttachmentType = (name: string) => {
    if (IMAGE_FORMATS.indexOf((name.substring(name.lastIndexOf('.')).toLowerCase()) as FileFormats) > -1) return 'Image';
    if (VIDEO_FORMATS.indexOf((name.substring(name.lastIndexOf('.')).toLowerCase()) as FileFormats) > -1) return 'Video';

    return undefined;
}

export const getDocumentCategory = (name: string) => {
    var attachmentType = getAttachmentType(name);
    if (attachmentType === 'Image') {
        return DocumentCategory.IncidentPhoto;
    } else if (attachmentType === 'Video') {
        return DocumentCategory.IncidentVideo;
    }

    return DocumentCategory.UnClassified;
}