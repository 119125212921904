import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useOktaAuth } from "@okta/okta-react";
import config from "configuration/config";
import React from "react";

export const ApolloProviderWrapper = (props: { children: React.ReactNode }) => {
  const { authState } = useOktaAuth();

  const httpLink = createHttpLink({
    uri: config.resourceServer.api.resourceUri,
  });

  const authLink = setContext((_, { headers }) => {
    const token = authState?.accessToken?.accessToken;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
