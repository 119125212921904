import { gql, useQuery } from "@apollo/client";

export interface IArcModel {
  id: number;
  arcName: string;
  address: string;
  phoneNumber: string;
}

export const useArcList = (insurerCode: string | undefined) => {
  const { loading, data, refetch, error } = useQuery(ARC_LIST, { variables: { insurerCode }, skip: !insurerCode });

  const arcList: IArcModel[] = data?.arcForInsurer ?? [];

  return { loading, arcList, refetch, error };
};

const ARC_LIST = gql`
  query getArcForInsurer($insurerCode: ShiftEnum_InsurerCode) {
    arcForInsurer(insurerCode: $insurerCode) {
      id
      arcName
      address
      phoneNumber
    }
  }
`;
