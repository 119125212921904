import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  padding-top: 0;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  width: 500px;

  @media (max-width: 548px) {
    width: 100%;
  }
`;

ContentWrapper.displayName = "ContentWrapper"