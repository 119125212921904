import { gql } from "@apollo/client";
import { useMemo } from "react";
import { IReportState } from "state";
import { formatError } from "utils/formatters";
import { mapReportStateToGears } from "utils/mapping/mapReportStateToGears";
import { useMutationWithErrorHandling } from "./errorHandlers";

interface IInsertReportResponse {
  insertAccidentReport: {
    id?: number;
    generalInformation?: {
      accidentInformation: {
        referenceCode: string;
      };
    };
  };
}

export const useSubmitReport = (reportState: IReportState) => {
  const [insertForm, {loading, data, error}] = useMutationWithErrorHandling<IInsertReportResponse>(INSERT_REPORT);

  const accidentReport = useMemo(() => mapReportStateToGears(reportState), [reportState]);

  const onSubmit = async () => {
    try {
      await insertForm({
        variables: {
          accidentReport,
          saveAsDraft: true,
          source: 'AccidentReportSourceEnum.MobileAr',
        },
        fetchPolicy: "no-cache",
      });
    } catch (error) {
      // silent catch - errors handled by useMutationWithErrorHandling
    }
  };

  return [onSubmit, loading, data, formatError(error)] as const;
};

const INSERT_REPORT = gql(`
  mutation SubmitAccidentReport($accidentReport: AccidentReportInput!, $saveAsDraft: Boolean, $source: ShiftEnum_AccidentReportSourceEnum) {
      insertAccidentReport(accidentReport: $accidentReport, saveAsDraft: $saveAsDraft, source: $source) {
          id
          generalInformation {
              accidentInformation {
                  referenceCode
              }
          }
      }
  }`);
