import React from "react";
import { Grid, GridItem, SubmitButton } from "@shift/gears-design-system";
import { ContentWrapper } from "styles/Style.Media";
import { SecondaryTextButton } from "./SecondaryButton";
import { PageTitle } from "./PageTitle";
import { PageText } from "./PageText";
import { PageHeader } from "./PageHeader";
import { useNextRoute } from "hooks/useNextRoute";
import { routes } from "entrypoint/Routing";
import { PageStepper } from "./PageStepper";

export interface IReportPageWrapperProps {
  children: React.ReactNode;
  stepNumber: number;
  pageTitle: string;
  pageDescription?: string;
  onSubmitFn?: () => void;
  onSkipFn?: () => void;
  submitEnabled?: boolean;
  submitButtonText?: string;
  skipButtonText?: string;
  alternativeOnBackFn?: () => void;
  nextRoute: keyof typeof routes;
  isFormValid?: () => boolean;
  submitting?: boolean;
  blockNavigation?: boolean;
}

export const ReportPageWrapper: React.FC<IReportPageWrapperProps> = (props) => {
  const {
    children,
    onSubmitFn,
    onSkipFn,
    stepNumber,
    pageTitle,
    pageDescription,
    submitEnabled = true,
    submitButtonText = "Confirm and continue",
    skipButtonText = "Skip this section",
    alternativeOnBackFn,
    nextRoute,
    isFormValid,
    submitting,
    blockNavigation,
  } = props;

  const { goToNextRoute } = useNextRoute(nextRoute);

  const onSubmitInternal = async (fn: Exclude<IReportPageWrapperProps["onSubmitFn"], undefined>) => {
    if (!isFormValid || isFormValid()) {
      await fn();
      if (!blockNavigation) goToNextRoute();
    }
  };

  const onSkipInternal = (fn: Exclude<IReportPageWrapperProps["onSkipFn"], undefined>) => {
    fn();
    goToNextRoute();
  };

  return (
    <Grid direction="column" style={{ minHeight: "100%" }}>
      <GridItem>
        <PageHeader alternativeOnBackFn={alternativeOnBackFn} />
      </GridItem>
      <GridItem flex={100} style={{ display: "table" }}>
        <ContentWrapper>
          <PageStepper currentStep={stepNumber} />
          <PageTitle>{pageTitle}</PageTitle>
          {pageDescription && <PageText>{pageDescription}</PageText>}
          {children}
        </ContentWrapper>
      </GridItem>
      <GridItem flex={1} style={{ display: "inline-grid" }}>
        <ContentWrapper>
          <br />
          {onSubmitFn && (
            <>
              <SubmitButton onClick={() => onSubmitInternal(onSubmitFn)} disabled={!submitEnabled} submitting={submitting}>
                {submitButtonText}
              </SubmitButton>
              <br />
            </>
          )}
          {onSkipFn && (
            <>
              <SecondaryTextButton onClick={() => onSkipInternal(onSkipFn)}>{skipButtonText}</SecondaryTextButton>
              <br />
            </>
          )}
        </ContentWrapper>
      </GridItem>
    </Grid>
  );
};
