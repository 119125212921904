import { accidentHistoryReportApiEndpoint, apiEndpoint } from "./apiEndpoint";

const _env_ = {
  ...process.env,
  ...(window as any)._env_,
};

const ACCIDENT_HISTORY_REPORT = _env_.REACT_APP_ACCIDENT_HISTORY_REPORT;
const CLIENT_ID = _env_.REACT_APP_CLIENT_ID;
const ISSUER = _env_.REACT_APP_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = _env_.OKTA_TESTING_DISABLEHTTPSCHECK || false;
//const REDIRECT_URI = `${window.location.origin}/implicit/callback`;
const REDIRECT_URI = _env_.REACT_APP_REDIRECT_URI;
const ACCIDENT_HISTORY_REPORT_REDIRECT_URI = _env_.REACT_APP_ACCIDENT_HISTORY_REPORT_REDIRECT_URI;
const IDP = _env_.REACT_APP_IDP;

const MYINFO_AUTH_URL = _env_.REACT_APP_MY_INFO_AUTHURL;
const MYINFO_CLIENT_ID = _env_.REACT_APP_MY_INFO_CLIENT_ID;
const MYINFO_ACCIDENT_HISTORY_REPORT_CLIENT_ID = _env_.REACT_APP_MY_INFO_ACCIDENT_HISTORY_REPORT_CLIENT_ID;

const MYINFO_REDIRECT = _env_.REACT_APP_MY_INFO_REDIRECT;
const MYINFO_ACCIDENT_HISTORY_REPORT_REDIRECT = _env_.REACT_APP_MY_INFO_ACCIDENT_HISTORY_REPORT_REDIRECT;

const MYINFO_ATTRIBUTES = _env_.REACT_APP_MY_INFO_ATTRIBUTES;
const MYINFO_ACCIDENT_HISTORY_REPORT_ATTRIBUTES = _env_.REACT_APP_MY_INFO_ACCIDENT_HISTORY_REPORT_ATTRIBUTES;

const MYINFO_PURPOSE = _env_.REACT_APP_MY_INFO_PURPOSES;
const MYINFO_ENV = _env_.REACT_APP_MY_INFO_ENVIRONMENT;

const MYINFO_PERSON_URL = _env_.REACT_APP_MY_INFO_PERSON_URL;
const MYINFO_ACCIDENT_HISTORY_REPORT_PERSON_URL = _env_.REACT_APP_MY_INFO_ACCIDENT_HISTORY_REPORT_PERSON_URL;


const RESOURCE_URI = _env_.REACT_APP_RESOURCE_URI;
const ACCIDENT_HISTORY_REPORT_RESOURCE_URI = _env_.REACT_APP_ACCIDENT_HISTORY_REPORT_RESOURCE_URI;

const STRIPE_PURCHASE_URL = _env_.REACT_APP_STRIPE_PURCHASE_URL;
const ACCIDENT_HISTORY_REPORT_COST = _env_.REACT_APP_ACCIDENT_HISTORY_REPORT_COST;

// eslint-disable-next-line
export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    accidentHistoryReportRedirectUri: ACCIDENT_HISTORY_REPORT_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: false,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
    api: {
      documentUploadUri: `${RESOURCE_URI}/${apiEndpoint.documentUpload}`,
      resourceUri: `${RESOURCE_URI}/${apiEndpoint.resource}`,
      vehicleAccidentReportHistoryPrice: `${RESOURCE_URI}/${apiEndpoint.vehicleAccidentReportHistoryPrice}`,
    }
  },
  accidentHistoryReportResourceServer: {
    api: {
      vehicleAccidentReportHistoryPrice: `${ACCIDENT_HISTORY_REPORT_RESOURCE_URI}/${accidentHistoryReportApiEndpoint.vehicleAccidentReportHistoryPrice}`,
      logoutAccidentHistoryReport: `${ACCIDENT_HISTORY_REPORT_RESOURCE_URI}/${accidentHistoryReportApiEndpoint.logout}`,
      vehicleAccidentReportHistoryUri: `${ACCIDENT_HISTORY_REPORT_RESOURCE_URI}/${accidentHistoryReportApiEndpoint.vehicleAccidentReportHistory}`,
      vehicleAccidentReportHistoryStatus: `${ACCIDENT_HISTORY_REPORT_RESOURCE_URI}/${accidentHistoryReportApiEndpoint.vehicleAccidentReportStatus}`,
      vehicleAccidentReportHistoryCreate: `${ACCIDENT_HISTORY_REPORT_RESOURCE_URI}/${accidentHistoryReportApiEndpoint.vehicleAccidentReportCreateReport}`

    }
  },
  tokenParam: {
    idp: IDP,
    responseType: ['id_token'],
    responseMode: 'fragment',
    scopes: ['openid'],
    nonce: '51GePTswrm',
  },
  myInfo: {
    authUrl: MYINFO_AUTH_URL,
    clientId: MYINFO_CLIENT_ID,
    accidentHistoryReportClientId: MYINFO_ACCIDENT_HISTORY_REPORT_CLIENT_ID,
    redirectUrl: MYINFO_REDIRECT,
    accidentHistoryReportRedirectUrl: MYINFO_ACCIDENT_HISTORY_REPORT_REDIRECT,
    attributes: MYINFO_ATTRIBUTES,
    purpose: MYINFO_PURPOSE,
    environment: MYINFO_ENV,
    personUrl: MYINFO_PERSON_URL,
    accidentHistoryReportPersonUrl: MYINFO_ACCIDENT_HISTORY_REPORT_PERSON_URL,
    accidentHistoryReportAttributes: MYINFO_ACCIDENT_HISTORY_REPORT_ATTRIBUTES,
  },
  accidentHistoryReportModule: "true" === ACCIDENT_HISTORY_REPORT.toLowerCase(),
  accidentHistoryReportCost:ACCIDENT_HISTORY_REPORT_COST,
  stripePurchaseUrl: STRIPE_PURCHASE_URL,
};
