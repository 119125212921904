import React from "react";
import { ImageButton, PageText, PageTitle } from "components";
import logo from "resources/singpass_login_logo.png";
import { Step, StepDescription } from "components/Steps";
import styled from "styled-components";
import { ContentWrapper } from "styles/Style.Media";

export interface actionProps {
    action: () => void;
}

export const IntroductionReporting: React.FC<actionProps> = ({ action }) => {
    const ButtonContainer = styled(PageText)`
    display: flex;
    justify-content: center;
`;
    return (
        <ContentWrapper>
            <PageTitle>GEARS Online Accident Notification</PageTitle><PageText>
                This application is only intended for the
                <span style={{ fontWeight: "bolder" }}> driver to report an accident.</span>
                If you need urgent assistance, please contact your insurer directly.
            </PageText><PageText>
                <b>To report your accident you have to:</b>
            </PageText><Step stepId={1}>Notify your accident on this site</Step><StepDescription>
                Initiate the accident reporting by logging in with your Singpass and complete the GEARS Online Accident Notification.
            </StepDescription><Step stepId={2}>Report the accident at an ARC</Step><StepDescription>
                Bring your accident vehicle and <span style={{ fontWeight: "bolder" }}>accident report number</span> to an Authorised
                Reporting Centre (ARC) within 24 hours or by the next working day.
            </StepDescription><PageText>
                <b>Or, you can go directly to an ARC to report your accident.</b>
            </PageText><PageText>
                Submission of this GEARS Online Accident Notification does not constitute reporting under the GIA Motor Claims Framework
                (MCF). Failure to do so within the stipulated timeframe may impact your claim and/or policy renewal terms.
            </PageText><PageText>By clicking on the below button, you agree that your NRIC/FIN will be used by GEARS to log in.</PageText>
            <ButtonContainer>
                <ImageButton src={logo} alt="singpass login" onClick={action} />
            </ButtonContainer>
        </ContentWrapper>
    );
};