import { ApolloError } from "@apollo/client";

export const formatError = (error: ApolloError | undefined): string[] | undefined => {
  if (!error) return undefined;

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map((e) => e.message);
  }

  return [error.message];
};
