import React from 'react';
import styled from "styled-components";

export const ImageButton = (props: React.ComponentProps<typeof ImageElement>) => {
  return <ImageElement {...props} />
}

const ImageElement = styled.img`
  width: 100%;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;