import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { accidentHistoryReport, colors, theme, variants } from "../../src/constants";
import { ThemeProvider } from "@shift/gears-design-system";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "configuration/config";
import { AppStateProvider } from "state";
import { routes } from "./Routing";
import { ApolloProviderWrapper } from "components";
import { ErrorMessageProvider } from "components/ErrorMessageProvider";
import { FetchProvider } from "utils/fetch";


export const Providers = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const onVarhPage = location.pathname.includes(accidentHistoryReport) && config.accidentHistoryReportModule;
  const oktaAuth = new OktaAuth({
    ...config.oidc,
    redirectUri: config.oidc.redirectUri,
    postLogoutRedirectUri: window.location.origin
  });
  const history = useHistory();
  const restoreOriginalUri = async (oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(routes.checkUser || "/", window.location.origin));
  };

  const accessToken = async () => {
    const isAuthenticated = await oktaAuth.isAuthenticated();
    if (isAuthenticated) {
      return (await oktaAuth.tokenManager.getTokens()).accessToken;
    }
    return null;
  };

  const accidentReportingProvider = () => {
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <ThemeProvider colors={colors} theme={theme} variants={variants}>
          <ErrorMessageProvider>
            <ApolloProviderWrapper>
              <FetchProvider token={accessToken}>
                <AppStateProvider>{children}</AppStateProvider>
              </FetchProvider>
            </ApolloProviderWrapper>
          </ErrorMessageProvider>
        </ThemeProvider>
      </Security>
    );
  }

  const vehicleAccidentHistoryReportProvider = () => {
    return (
      <ThemeProvider colors={colors} theme={theme} variants={variants}>
        <ErrorMessageProvider>
            <FetchProvider>
              <AppStateProvider>{children}</AppStateProvider>
            </FetchProvider>
        </ErrorMessageProvider>
      </ThemeProvider>
    );
  }
  return (
    <>
      {!onVarhPage ?

        accidentReportingProvider()
        :
        vehicleAccidentHistoryReportProvider()
      }

    </>
  );

};
