import { FileFormats } from '@shift/gears-design-system';

export * from './theme';
export * from './colors';
export * from './variants';
export * from './rows';
export * from './dates';

export const accidentHistoryReport = 'accident-history-report';
export const accidentNotification = 'accident-notification';

export const draftStageEnum = 'AtDraftStage';
export const privateHireVehicle = 'VehicleType.PrivateHire';

export const inProgressObjectEnum = { Type: 'ClosingType', Name: 'InProgress' };
export const closedObjectEnum = { Type: 'ClosingType', Name: 'Closed' };
export const draftObjectEnum = { Type: 'ClosingType', Name: 'AtDraftStage' };
export const notificationOnlyObjectEnum = { Type: 'ClosingType', Name: 'NotificationOnly' };

export const otherEnum = 'OtherEnum.Other';
export const unknownEnum = 'OtherEnum.Unknown';
export const unknownObjectEnum = { Type: 'OtherEnum', Name: 'Unknown' };
export const phoneTypeEnum = 'PhoneNumberType';
export const companyRegistrationNumberEnum = 'NationalIdentifierType.CompanyIdentificationNumber';
export const nationalIdentifierEnum = { Type: 'NationalIdentifierType', Name: 'NationalIdentifier' };
export const phoneType = 'Phone';
export const faxType = 'Fax';
export const maxTextAreaCharacterLength = 2000;
export const reportBillableItemName = 'Accident Report History Purchase';

export const sgCountryCode = {
    countryCode: 'SG',
    dialCode: '65',
};

export const localeCode = "en-SG";

// Recovery
export type PartyEnum = 'InitiatingParty' | 'ReceivingParty' | 'NeutralParty';
export const initiatingPartyEnum = 'InitiatingParty';
export const receivingPartyEnum = 'ReceivingParty';
export const neutralPartyEnum = 'NeutralParty';

export const recoveryInitiatingParty = 1;
export const recoveryReceivingParty = 2;


export const recoverableAmountEnum = 'AmountRecoverable';
export const recoveredAmountEnum = 'AmountRecovered';

export const bolaCaseCommentType = {
    name: 'WorkflowStepCommentType.InsurerPosition',
    id: 2,
};

export const insuredCommentType = {
    name: 'WorkflowStepCommentType.InsuredPosition',
    id: 3,
};

export const managerCommentType = {
    name: 'WorkflowStepCommentType.WaiveRemarks',
    id: 4,
};

export const recoveryCommentEnumValue = 1;

export const thirdPartyVehicleDamageEnumValue = 2;

export const recoveryTypeThirdPartyDamage = 'MotorRecoveryType.ThirdPartyVehicleDamage';

export const recoveryActions = {
    initiate: 'Initiate',
    waive: 'Waive',
    accept: 'Accept',
    sendback: 'Sendback',
    counterOffer: 'CounterOffer',
    finalOffer: 'FinalOffer',
    sendPayment: 'SendPayment',
    acknowledgePayment: 'AcknowledgePayment',
    drmInitiateEscalation: 'DrmInitiateEscalation',
    drmApproveInitiateEscalation: 'DrmApproveInitiateEscalation',
    drmRespondEscalation: 'DrmRespondEscalation',
    drmApproveRespondEscalation: 'DrmApproveRespondEscalation',
    drmWithdraw: 'DrmWithdraw',
    drmAcknowledgeWithdraw: 'DrmAcknowledgeWithdraw',
    drmRejectWithdraw: 'DrmRejectWithdraw',
    drmCancel: 'DrmCancel',
    drmGiaAdjudicate: 'DrmGiaAdjudicate',
    drmGiaReject: 'DrmGiaReject',
    reinitiate: 'Reinitiate',
    default: 'Default',
    defaultPayment: 'DefaultPayment',
};

export const recoveryStates = {
    pendingCounterActionByRp: 'PendingCounterActionByRp',
    pendingSecondActionByIp: 'PendingSecondActionByIp',
    pendingFinalActionByRp: 'PendingFinalActionByRp',
    sentback: 'Sentback',
    closed: 'Closed',
    pendingPayment: 'PendingPayment',
    pendingPaymentAcknowledgeByIp: 'PendingPaymentAcknowledgeByIp',
    paymentDefaulted: 'PaymentDefaulted',
    pendingDrmApproveInitiateEscalation: 'PendingDrmApproveInitiateEscalation',
    pendingDrmEscalationResponseByCounterParty: 'PendingDrmEscalationResponseByCounterParty',
    pendingDrmApproveRespondEscalation: 'PendingDrmApproveRespondEscalation',
    pendingDrmAcknowledgeWithdrawal: 'PendingDrmAcknowledgeWithdrawal',
    pendingDrmGiaAdjudication: 'PendingDrmGiaAdjudication',
};


export const WorflowPartySide = {
    InitiatingParty: 1,
    ReceivingParty: 2,
    ThirdParty: 3,
};

export const recoveryDrmWithdrawReasons = {
    InitiatingParty: 'RecoveryIPWithdrawReason',
    ReceivingParty: 'RecoveryRPWithdrawReason',
    NeutralParty: 'RecoveryNPWithdrawReason',
};

export enum RecoveryWorkflowStepDeadlineState {
    WaitingAction = 'WaitingAction',
    NoActionRequired = 'NoActionRequired',
    SentBack = 'SentBack',
    Completed = 'Completed'
}

export const WaiveAcceptReason = 3;
export const WithdrawAcceptReason = 2;
export const WithdrawAmicableReason = 3;

export const IMAGE_FORMATS = [
    FileFormats.JPEG,
    FileFormats.JPG,
    FileFormats.TIF,
    FileFormats.TIFF,
    FileFormats.PNG,
    FileFormats.WEBP,
];

export const VIDEO_FORMATS = [
    FileFormats.MP4,
    FileFormats.AVI,
    FileFormats.MOV,
    FileFormats.MKV,
    FileFormats._3GP
];

export const GearsVehicleAccidentReportHistoryTitle = 'GEARS Vehicle Accident Report History'

export const purchaseStatuses = {
    paid: 'Paid',
    failed: 'Failed',
    received: 'Received', 
    sent: 'Sent',
    emailSendingFailed: 'EmailSendingFailed',
    reportGenerationFailed: 'ReportGenerationFailed',
    reportGenerated: 'ReportGenerated',
    paymentFailed: 'PaymentFailed'

};
