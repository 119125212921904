import config from "configuration/config";

import { ReactElement } from 'react';


export interface Transformer<In, Out, Options = {}> {
    transform(raw: In, options?: Options): Out;
}
export declare type Formatter<In, Out, Options = {}> = Transformer<In[], Out, Options>;

export interface DownloadWidgetterProps {
    children: string | ReactElement;
    filename?: string;
    url: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PDFLinkFormatter: Formatter<any, DownloadWidgetterProps> = {
    transform: (params) => {
        const values = params && params.flat();
        const [id] = values;
        return {
            url: id? `${config.accidentHistoryReportResourceServer.api.vehicleAccidentReportHistoryUri}/${id}` : '',
            children: "Download"
        };
    },
};