import React, { useState } from 'react';
import { IntroductionAccidentHistoryReport } from './IntroductionAccidentHistoryReport';
import { routes } from 'entrypoint/Routing';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

export const HomeAccidentHistoryReport = (props: any) => {
    const {authenticated} = useAuth();

    return (
        !authenticated?
            <IntroductionAccidentHistoryReport props={props} /> : <Redirect to={routes.accidentHistoryReportContactInfo} />
    );
};