import React, { useState } from "react";
import { SelectInput, SelectInputOption } from "@shift/gears-design-system";
import { ReportPageWrapper, PaddedInputForm } from "components";
import { useAppState } from "state";
import { useInsurerList } from "../../hooks/useInsurerList";

export const InsurerInformation = () => {
  const {
    reportState: { insurerInfo },
    updateReportState,
  } = useAppState();

  const [selectedInsurer, setSelectedInsurer] = useState<SelectInputOption | undefined>(insurerInfo?.insurer);  

  const { loading: loadingInsurers, options: insurers } = useInsurerList();  


  const onSubmit = () => {
    updateReportState({ insurerInfo: { insurer: selectedInsurer } });
  };

  const onSkip = () => {
    updateReportState({ insurerInfo: undefined });
  };

  const onInsurerSelection = (e: SelectInputOption | undefined) => {
    setSelectedInsurer(e);
  };

  const valuesProvided = !!(selectedInsurer && !loadingInsurers);

  return (
    <ReportPageWrapper
      stepNumber={5}
      pageTitle="Insurer information"
      pageDescription="Please enter the following information"
      onSubmitFn={onSubmit}
      onSkipFn={onSkip}
      submitEnabled={valuesProvided}
      nextRoute="reviewMyInfoDetails"
    >
      <PaddedInputForm label="Handling insurer (optional)">
        <SelectInput
          options={insurers}
          onChange={(e) => onInsurerSelection(e as unknown as SelectInputOption)}
          value={loadingInsurers || !selectedInsurer ? undefined : [selectedInsurer]}
          disabled={loadingInsurers}
          placeholder={loadingInsurers ? "Loading..." : undefined}
        ></SelectInput>
      </PaddedInputForm>      
    </ReportPageWrapper>
  );
};