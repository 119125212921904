const getImage = (dataUrl: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const image = new window.Image();
  image.src = dataUrl;
  image.onload = () => {
      resolve(image);
  };
  image.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
      reject(error);
  };
});

export const downscaleImage = (
  dataUrl: string,
  imageType: string, // e.g. 'image/jpeg'
  resolution: number, // max width/height in pixels
  quality: number, // e.g. 0.9 = 90% quality
  cb: (dowscaledImage: string | undefined) => void,
) => {
  // Create a temporary image so that we can compute the height of the image.
  getImage(dataUrl).then((image) => {
      const oldWidth = image.naturalWidth;
      const oldHeight = image.naturalHeight;

      const longestDimension = oldWidth > oldHeight ? 'width' : 'height';
      const currentRes = longestDimension === 'width' ? oldWidth : oldHeight;
      resolution = currentRes > resolution ? resolution : currentRes;
      //if (currentRes > resolution) {
          // Calculate new dimensions
          const newSize = longestDimension === 'width'
              ? Math.floor((oldHeight / oldWidth) * resolution)
              : Math.floor((oldWidth / oldHeight) * resolution);
          const newWidth = longestDimension === 'width' ? resolution : newSize;
          const newHeight = longestDimension === 'height' ? resolution : newSize;

          // Create a temporary canvas to draw the downscaled image on.
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          // Draw the downscaled image on the canvas and return the new data URL.
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(image, 0, 0, newWidth, newHeight);
          const newDataUrl = canvas.toDataURL(imageType, quality);
          cb(newDataUrl);
          return;
      //}

      //cb(dataUrl);
  }).catch((e) => cb(undefined));
};