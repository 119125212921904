import React, { useEffect, useRef, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  CollapsePanel,
  DEFAULT_VARIANT_NAME,
  FileFormats,
  FileInfo,
  FilesUploadWithPreview,
  StyledSubHeader,
} from "@shift/gears-design-system";
import { ReportPageWrapper, PageSectionTitle, PageText } from "components";
import { useHistory } from "react-router-dom";
import { useAppState } from "state";
import image from "resources/upload_photo.png";

import styled from "styled-components";
import { photoUploadHandler } from "./photoUploadHandler";
import { genericErrorMessage } from "utils/documents/uploadFile";
import { getDocumentCategory } from "utils/documents/typesAndCategories";



const maxFileSizeMB = 50,
  maxNumberOfPhotos = 30,
  supportedFileFormats = [FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF, FileFormats.MP4, FileFormats.AVI, FileFormats.MKV, FileFormats.MOV, FileFormats._3GP];

export interface IFileInfoExtended extends FileInfo {
  abortUpload?: () => void;
}

export const PhotoUpload = () => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const { reportState, updateReportState } = useAppState();

  const [attachments, setAttachments] = useState<IFileInfoExtended[]>(reportState.attachments ?? []);
  const attachmentsRef = useRef<typeof attachments>([]);

  useEffect(() => {
    // This effect runs only on first page load.
    // If we have unfinished upload at this point, it will never finish.
    // Probably user refreshed the screen while upload was in progress.
    if (attachments.find((x) => x.progress)) {
      setAttachments((prev) =>
        prev.map((x) => {
          if (x.progress) {
            x.progress = undefined;
            x.errorMessage = genericErrorMessage;
            return { ...x };
          }
          return x;
        })
      );
    }

    return () => {
      // abort peding uploads if we are leaving the page
      attachmentsRef.current.filter((x) => x.progress).forEach((x) => x.abortUpload?.());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateReportState({ attachments: attachments });
    attachmentsRef.current = attachments;
  }, [attachments, updateReportState]);
  const onAddPhoto = (newFiles: FileList) => {
    if (attachments.length + newFiles.length > maxNumberOfPhotos) {
      alert(
        `Maximal number of photos exceeded (${maxNumberOfPhotos}). Selected files: ${newFiles.length}, available slots: ${maxNumberOfPhotos - attachments.length
        }`
      );
      return;
    }

    let tempId = Math.min(...attachments.map((file) => file.id), 0);

    Array.from(newFiles).forEach((x) => {
      photoUploadHandler(
        --tempId,
        x,
        maxFileSizeMB,
        setAttachments,
        async () => oktaAuth.getAccessToken() ?? null,
        getDocumentCategory(x.name)
      );
    });
  };

  const onRemovePhoto = (id: number) => {
    attachments.find((x) => x.id === id)?.abortUpload?.();
    setAttachments((prev) => {
      return [...prev.filter((p) => p.id !== id)];
    });
  };

  const photosSelected = !!attachments?.length;
  const disableSubmit = !!attachments.find((x) => x.errorMessage || x.progress);
  const pendingUpload = !!attachments.find((x) => x.progress);
  const uploadEnabled = attachments.length < maxNumberOfPhotos;

  const alertText = "Upload will fail if you leave the page, are you sure?";

  return (
    <ReportPageWrapper
      stepNumber={4}
      pageTitle="Take or upload photos and videos of the scene and the vehicle(s)"
      pageDescription="Capture the accident vehicles and surrounding areas."
      onSubmitFn={photosSelected ? () => null : undefined}
      onSkipFn={photosSelected ? undefined : () => null}
      submitEnabled={!disableSubmit}
      submitButtonText={disableSubmit ? "Photo upload in progress or failed" : "Confirm and continue"}
      alternativeOnBackFn={() => {
        if (pendingUpload) {
          if (window.confirm(alertText)) history.goBack();
        } else history.goBack();
      }}
      nextRoute="insurerInfo"
    >
      <CollapsablePageDescription isOpen={!photosSelected} />
      <AddPhotoButtonStyle style={photosSelected ? undefined : { display: "table-footer-group" }}>
        <FilesUploadWithPreview
          onClickOnFile={() => null}
          uploadButtonText={
            uploadEnabled ? (photosSelected ? "Add more photos and videos" : "Add photos and videos") : `Max photos and videos reached (${maxNumberOfPhotos})`
          }
          acceptedFormats={supportedFileFormats}
          onAdd={onAddPhoto}
          onRemove={onRemovePhoto}
          files={attachments}
          uploadButtonVariants={photosSelected ? DEFAULT_VARIANT_NAME.button.secondary : undefined}
          pageSize={maxNumberOfPhotos}
        />
      </AddPhotoButtonStyle>
    </ReportPageWrapper>
  );
};

const BoldText = styled.span`
  font-weight: bold;
`;

const CollapsablePageDescription = ({ isOpen }: { isOpen: boolean }) => (
  <CollapsablePanelStyle>
    <CollapsePanel header={<PageSectionTitle>What to photograph?</PageSectionTitle>} isOpen={isOpen} key={`${isOpen}`}>
      <div>
        <img src={image} alt="" style={{ width: "100%", marginBottom: "10px" }} />
      </div>
      <StyledSubHeader type="s2" weight="bold">
        Scene {isOpen}
      </StyledSubHeader>
      <PageText>Take pictures and videos of the accident scene capturing the accident vehicles and surrounding areas.</PageText>
      <StyledSubHeader type="s2" weight="bold">
        Own vehicle
      </StyledSubHeader>
      <PageText>
        Take pictures and videos of the damages to your own vehicle <BoldText>capturing your license plate</BoldText>.
      </PageText>
      <StyledSubHeader type="s2" weight="bold">
        Other damaged vehicle(s)
      </StyledSubHeader>
      <PageText>
        Take pictures and videos of the damages to all other vehicles involved in the accident <BoldText>capturing license plates</BoldText>.
        For multiple vehicle collision, take pictures of those with direct contact, e.g, immediate front and immediate rear
        vehicles.
      </PageText>
      <PageText>
        <div>
          <b>Supported formats:</b> jpg, jpeg, png, tif, tiff, mp4, avi, 3gp, mkv, mov.
        </div>
        <div>
          <b>Max file size:</b> {maxFileSizeMB} MB.
        </div>
      </PageText>
    </CollapsePanel>
  </CollapsablePanelStyle>
);

const AddPhotoButtonStyle = styled.div`
  > div > div:nth-child(2) > div > div > div > button {
    width: 100%;
  }
  > div > div:nth-child(2) > div > div > div {
    width: 100%;
  }
  > div > div:nth-child(2) > div > div {
    width: 100%;
  }
  > div > div:nth-child(1) > div > div {
    margin: 0 !important;
  }
  > div > div:nth-child(1) > div > div > div > div > div > div > div:nth-child(1) {
    word-break: break-all;
  }
`;

const CollapsablePanelStyle = styled.div`
  > div > div {
    padding: 0;
  }
`;
