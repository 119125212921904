import config from "configuration/config";
import { routes } from "entrypoint/Routing";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppState } from "state";

export const useAuth = () => {
  const { reportState, clearReportState } = useAppState();
  const history = useHistory();
  const [authenticated] = useState(reportState.authenticationInfo?.isAuthenticated);
  return {
    authenticated,
    logout() {
      return new Promise(async () => {
        try {
          const response = await fetch(config.accidentHistoryReportResourceServer.api.logoutAccidentHistoryReport, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            credentials: 'include',
          });

          if (response.status === 401 || response.ok) {
            clearReportState();
            history.push(routes.accidentHistoryReport);
          }      
            throw new Error(`Error while executing the request.`);    

        } catch (err) {
          throw new Error('Error while executing the request.');
      }
      });
    },
   onUnauthorized() {
      clearReportState();
      history.push(routes.accidentHistoryReport);
  }
  }
};