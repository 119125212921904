import React from "react";
import { StyledHeader } from "@shift/gears-design-system";
import { colors } from "constants/colors";
import styled from "styled-components";

interface PageTitleProps {
  children: string, 
  alignment?: undefined | "center"
}

const Content = styled.div`
  margin: 20px 0 5px 0;
`;

export const PageTitle = ({ children, alignment }: PageTitleProps) => {
  return (
    <StyledHeader textAlign={alignment} type="h1" weight="semiBold" color={colors.text?.header}>
      <Content>{children}</Content>
    </StyledHeader>
  );
};
