import { CircularLoader, ThemeContext } from '@shift/gears-design-system';
import config from 'configuration/config';
import { routes } from 'entrypoint/Routing';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { mapMyInfoToReportState } from 'utils/mapping/mapMyInfoToReportState';
import { useQueryStringParams } from 'hooks';
import { useAppState } from 'state';

enum MyinfoErrors {
    AccessDenied = "access_denied"
}

export const CallbackAccidentReporting = () => {
    let query = useQueryStringParams();

    const { updateReportState } = useAppState();
    const history = useHistory();

    const { colors } = useContext(ThemeContext);

    const myinfoAuthCode: string = query.get("code") + "";
    const myinfoAuthState: string = query.get("state") + "";
    const myinfoError = query.get("error");
    const requestData = {
        "authCode": myinfoAuthCode,
        "state": myinfoAuthState,
        "simplifiedMyinfoRequest": false,
        "mobileReporting": true,
    };

    var personDataUrl = config.myInfo.personUrl + "";
    var personalInfoUrl = routes.personalInfo;
    var callbackUrl = routes.callback;
    var contactInfo = routes.contactInfo;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(requestData)
    }

    useEffect(() => {
        if (myinfoError === MyinfoErrors.AccessDenied) {
            history.replace(personalInfoUrl, { referrer: callbackUrl });
            return;
        }

        const fetchData = async () => {
            try {
                const response = await fetch(personDataUrl, requestOptions);
                const json = await response.json();
                if (!response.ok) {
                    throw new Error("Failed to retrieve personal data, contact support.", { cause: json })
                }
                updateReportState(mapMyInfoToReportState(json));
                history.replace(contactInfo);
            } catch (error: any) {
                history.replace(personalInfoUrl, {
                    error: `Failed to retrieve personal data, contact support. ${error?.cause?.traceId ? " Support trace number: " + error.cause.traceId : ""}`
                });
            }
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div style={{ margin: "auto" }}>
                <CircularLoader color={colors.brand.primary.base} width='16px' size='100px' />
            </div>
        </>
    );
};
